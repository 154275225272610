import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config';
import logo from '../imagecollection/applogo.png'; // Ensure this path is correct

const ServiceAgreementPopup = ({ setCharges }) => {
    const navigate = useNavigate();
    const [contractData, setContractData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const params = useParams();

    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    useEffect(() => {
        axios.get(`${config.API_ENDPOINT}/contracts/contract-templates/${params.id}/`, { headers: getAuthHeaders() })
            .then(response => {
                if (response.data && Object.keys(response.data).length > 0) {
                    setContractData(response.data);
                    setCharges(response.data.charge);
                } else {
                    setContractData({});
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the contract data!", error);
                setError(true);
                setLoading(false);
            });
    }, [params.id, setCharges]);

    if (loading) {
        return (
            <div className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error || !contractData || Object.keys(contractData).length === 0) {
        return (
            <div className="text-center mt-5">
                <h5>No agreement found</h5>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="card p-4 bg-light border-primary rounded">
                <div className="text-center mb-4">
                    <img src={logo} alt="Company Logo" className="img-fluid" style={{ maxWidth: '200px' }} />
                </div>
                <h4 className="card-title text-center mb-4">contract agreement</h4>

               




                <div className="card-body">

                <div className="mb-3">
                        <h5>Template Title</h5>
                        <p>{contractData.title}</p>
                    </div>
                    <div className="mb-3">
                        <h5>Terms</h5>
                        <ul>
                            {contractData.template_terms.split('\n').map((term, index) => (
                                <li key={index}>{term}</li>
                            ))}
                        </ul>
                    </div>

                    
                    <div className="mb-3">
                        <h5>Mistress Role</h5>
                        <p>{contractData.teacher_role}</p>
                    </div>
                    <div className="mb-3">
                        <h5>Slave Role</h5>
                        <p>{contractData.student_role}</p>
                    </div>
                    <div className="mb-3">
                        <h5>Daily Tasks</h5>
                        <p>{contractData.daily_tasks}</p>
                    </div>
                    <div className="mb-3">
                        <h5>Charge</h5>
                        <p>${contractData.charge}</p>
                    </div>
                    <div className="text-center mt-4">
                        <p><strong>Important:</strong> Once you sign the contract, you must follow the daily tasks as outlined. Non-compliance may result in penalties. Ensure you understand all terms before proceeding.</p>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <button 
                            className='btn btn-success' 
                            onClick={() => {
                                navigate("/PayPalButton");
                                localStorage.setItem('masterId', params.id);
                                localStorage.setItem("userId", "1");
                                localStorage.setItem("charge", contractData.charge);
                            }}
                        >
                            Continue
                        </button>
                        <button 
                            className='btn btn-danger' 
                            onClick={() => navigate("/")}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceAgreementPopup;
