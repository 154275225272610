import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

function VideoSection({ title, videos, onDelete, setShowPopup, profileId, userId, setTeacherUploadVideo }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [commentInputs, setCommentInputs] = useState({});
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState('');
  const [parentReplyId, setParentReplyId] = useState(null);
  const [replyInputs, setReplyInputs] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  const videosPerPage = 3;
  const validVideos = Array.isArray(videos) ? videos : [];

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = validVideos.slice(indexOfFirstVideo, indexOfLastVideo);
  const totalPages = Math.ceil(videos.length / videosPerPage);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('access_token');
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  // Pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Add Comment
  const onCommentSubmit = async (videoId) => {
    try {
      const commentContent = commentInputs[videoId] || '';
      await axios.post(
        `${config.API_ENDPOINT}/video/video/${videoId}/comment/`,
        { content: commentContent },
        { headers: getAuthHeaders() }
      );
      setCommentInputs(prev => ({ ...prev, [videoId]: '' })); // Clear input after submission
      setFeedbackMessage(`Comment added successfully: "${commentContent}"`);
      alert(`Comment added successfully: "${commentContent}"`)
    } catch (error) {
      console.error('Error adding comment:', error);
      setFeedbackMessage('Failed to add comment. Please try again.');
      alert('Failed to add comment. Please try again.');
    }
  };

  // Edit Comment
  const handleEditSave = async (commentId, videoId) => {
    try {
      await axios.put(
        `${config.API_ENDPOINT}/video/comment/${commentId}/edit/`,
        { content: editCommentContent },
        { headers: getAuthHeaders() }
      );
      setEditCommentId(null); // Exit edit mode
      setFeedbackMessage('Comment edited successfully.');
      alert('Comment edited successfully.')
    } catch (error) {
      console.error('Error editing comment:', error);
      setFeedbackMessage('Failed to edit comment. Please try again.');
      alert('Failed to edit comment. Please try again.')
    }
  };

  // Reply to Comment
  const handleReplySubmit = async (commentId, videoId) => {
    try {
      const content = {
        "content": replyInputs[commentId] || '',
        "parent_id": parentReplyId
      };
      await axios.post(
        `${config.API_ENDPOINT}/video/video/${videoId}/comment/`,
        content,
        { headers: getAuthHeaders() }
      );
      setReplyInputs(prev => ({ ...prev, [commentId]: '' })); // Clear reply input
      setParentReplyId(null); // Reset reply target
      setFeedbackMessage('Reply added successfully.');
      alert('Reply added successfully.')
    } catch (error) {
      console.error('Error replying to comment:', error);
      setFeedbackMessage('Failed to add reply. Please try again.');
      alert('Failed to add reply. Please try again.')
    }
  };

  // Delete Comment
  const handleDeleteComment = async (commentId) => {
    try {
      await axios.delete(
        `${config.API_ENDPOINT}/video/comment/${commentId}/delete/`,
        { headers: getAuthHeaders() }
      );
      setFeedbackMessage('Comment deleted successfully.');
      alert('Comment deleted successfully.')
    } catch (error) {
      console.error('Error deleting comment:', error);
      setFeedbackMessage('Failed to delete comment. Please try again.');
      alert('Failed to delete comment. Please try again.')
    }
  };

  const handleCommentInputChange = (e, videoId) => {
    setCommentInputs(prev => ({ ...prev, [videoId]: e.target.value }));
  };

  const handleReplyChange = (e, commentId) => {
    setReplyInputs(prev => ({ ...prev, [commentId]: e.target.value }));
  };

  const handleEditClick = (comment) => {
    setEditCommentId(comment.id);
    setEditCommentContent(comment.content);
  };

  const renderComments = (comments, videoId) => {
    if (!Array.isArray(comments) || comments.length === 0) {
      return <p className="text-muted">No comments available</p>;
    }

    const renderCommentTree = (comment) => (
      <div key={comment.id} className="mb-3">
        {editCommentId === comment.id ? (
          <div className="d-flex align-items-center">
            <input
              value={editCommentContent}
              onChange={(e) => setEditCommentContent(e.target.value)}
              className="form-control"
            />
            <button
              className="btn btn-success ms-2"
              onClick={() => handleEditSave(comment.id, videoId)}
            >
              Save
            </button>
            <button
              className="btn btn-secondary ms-2"
              onClick={() => setEditCommentId(null)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <p>
            <strong>{comment.user}</strong>: {comment.content}{' '}
            {comment.user === userId && (
              <>
                <span
                  className="text-primary ms-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEditClick(comment)}
                >
                  Edit
                </span>
                <span
                  className="text-danger ms-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteComment(comment.id)}
                >
                  Delete
                </span>
              </>
            )}
            <span
              className="text-success ms-2"
              style={{ cursor: 'pointer' }}
              onClick={() => setParentReplyId(comment.id)}
            >
              Reply
            </span>
          </p>
        )}

        {parentReplyId === comment.id && (
          <div className="mt-2">
            <input
              type="text"
              value={replyInputs[comment.id] || ''}
              onChange={(e) => handleReplyChange(e, comment.id)}
              placeholder="Write a reply..."
              className="form-control"
            />
            <button
              className="btn btn-success mt-2"
              onClick={() => handleReplySubmit(comment.id, videoId)}
            >
              Submit
            </button>
          </div>
        )}

        {comment.replies && comment.replies.length > 0 && (
          <div className="ms-4">
            {comment.replies.map(reply => renderCommentTree(reply))}
          </div>
        )}
      </div>
    );

    return comments.map(comment => renderCommentTree(comment));
  };

  return (
    <div className="col-12 col-md-8 col-lg-6">
      <div className="card mb-4">
        <div className="card-body">
          <h5 className="card-title">{title}</h5>

          {feedbackMessage && (
            <div className={`alert ${feedbackMessage.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
              {feedbackMessage}
            </div>
          )}
          
          <div className="card-text">
            {title === "Slave Videos" && (
              <button className='btn btn-primary mb-3' onClick={() => setShowPopup(true)}>
                Upload Video
              </button>
            )}
            {videos.length > 0 ? (
              <>
                <ul className="list-unstyled">
                  {currentVideos.map(video => (
                    <li key={video.id} className="mb-4">
                      <h6 className="mb-2">{video.title}</h6>
                      <video className="w-100" controls>
                        <source src={video.file} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {String(userId) === String(profileId) && (
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => onDelete(video.id)}
                      >
                        Delete
                      </button>
                    )}
                      <div className="mt-3">
                        <input
                          type="text"
                          placeholder="Comment"
                          value={commentInputs[video.id] || ''}
                          onChange={(e) => handleCommentInputChange(e, video.id)}
                          className="form-control mb-2"
                        />
                        <button
                          className="btn btn-success"
                          onClick={() => onCommentSubmit(video.id)}
                        >
                          Submit
                        </button>
                        <div className="mt-3">
                          {video.comments && renderComments(video.comments, video.id)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, i) => (
                      <li key={i} className="page-item">
                        <button
                          className={`page-link ${currentPage === i + 1 ? 'active' : ''}`}
                          onClick={() => setCurrentPage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </>
            ) : (
              <p>No videos available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;
