import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';

function ServiceAgreement({ userId }) {
  return (
    <div className="container mt-5">
      <div className="p-5 border rounded bg-white shadow-lg">
        <h2 className="text-primary mb-4 text-center">Service Agreement</h2>
        
        <p className="text-muted lead mb-4 text-center">
          By booking this appointment, you acknowledge and agree to the terms governing the relationship between the mistress and the submissive.
        </p>
        
        <div className="alert alert-info p-3 mb-4" role="alert">
          <FaInfoCircle className="me-2" /> Please read the following terms carefully before proceeding.
        </div>
        
        <ul className="list-group list-group-flush mb-4">
          <li className="list-group-item">
            <FaCheckCircle className="text-success me-2" />
            The mistress and the submissive must both consent to the terms outlined.
          </li>
          <li className="list-group-item">
            <FaCheckCircle className="text-success me-2" />
            All activities will be conducted within the agreed-upon boundaries and limits.
          </li>
          <li className="list-group-item">
            <FaCheckCircle className="text-success me-2" />
            Confidentiality and privacy of both parties will be maintained.
          </li>
          <li className="list-group-item">
            <FaCheckCircle className="text-success me-2" />
            Any changes to the terms must be mutually agreed upon.
          </li>
        </ul>

        <p className="text-muted text-center">
          By proceeding, you confirm that you have read, understood, and agreed to these terms.
        </p>
        
        <div className="text-center">
          <Link to={`/service-agreement/${userId}`} className="btn btn-lg btn-primary px-5">
            Accept and Continue
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceAgreement;
