import React from 'react';

const ContactUs = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Last updated on 02-09-2024 00:43:51</p>
      <p>You may contact us using the information below:</p>
      <p><strong>Registered Address:</strong> Vishnu Enclave, CKB Layout, Munnekolala, Marathahalli, Bengaluru, Karnataka 560037, Bangalore, Karnataka, PIN: 560037</p>
      <p><strong>Operational Address:</strong> Vishnu Enclave, CKB Layout, Munnekolala, Marathahalli, Bengaluru, Karnataka 560037, Bangalore, Karnataka, PIN: 560037</p>

      <p><strong>E-Mail ID:</strong> admin@strict-bdsm.com</p>
    </div>
  );
};

export default ContactUs;
