import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const EventDetails = () => {
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isOwner, setIsOwner] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedEvent, setEditedEvent] = useState({});
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [editedCommentId, setEditedCommentId] = useState(null);
    const [editedCommentText, setEditedCommentText] = useState("");

    useEffect(() => {
        const fetchUserAndEventDetails = async () => {
            const token = localStorage.getItem('access_token');

            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const userResponse = await axios.get(`${config.API_ENDPOINT}/get-user/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const currentUserId = userResponse.data.user_id;
                setUserId(currentUserId);

                const eventResponse = await axios.get(`${config.API_ENDPOINT}/event_host/events/${id}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setEvent(eventResponse.data);
                setEditedEvent(eventResponse.data);

                const commentsResponse = await axios.get(`${config.API_ENDPOINT}/event_host/events/${id}/comments/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setComments(commentsResponse.data);

                if (String(eventResponse.data.created_by) === String(currentUserId)) {
                    setIsOwner(true);
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserAndEventDetails();
    }, [id]);

    const handleDelete = async () => {
        const token = localStorage.getItem('access_token');
        try {
            await axios.delete(`${config.API_ENDPOINT}/event_host/events/${id}/delete/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            navigate('/EventList');
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedEvent((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');
        try {
            await axios.put(`${config.API_ENDPOINT}/event_host/events/${id}/update/`, editedEvent, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsEditing(false);
            setEvent(editedEvent);
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');
        try {
            const response = await axios.post(`${config.API_ENDPOINT}/event_host/events/${id}/comments/create/`, {
                comment: newComment,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setComments((prevComments) => [...prevComments, response.data]);
            setNewComment("");
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handleEditCommentChange = (e) => {
        setEditedCommentText(e.target.value);
    };

    const handleEditComment = (commentId, text) => {
        setEditedCommentId(commentId);
        setEditedCommentText(text);
    };

    const handleUpdateComment = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');
        try {
            await axios.patch(`${config.API_ENDPOINT}/event_host/events/${id}/comments/${editedCommentId}/`, {
                comment: editedCommentText,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setComments((prevComments) =>
                prevComments.map((comment) =>
                    comment.id === editedCommentId ? { ...comment, comment: editedCommentText } : comment
                )
            );
            setEditedCommentId(null);
            setEditedCommentText("");
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        const token = localStorage.getItem('access_token');
        try {
            await axios.delete(`${config.API_ENDPOINT}/event_host/events/${id}/comments/${commentId}/delete/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setComments((prevComments) => prevComments.filter((comment) => comment.id !== commentId));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
        <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>;
    }

    if (!event) {
        return <div>No event found.</div>;
    }

    return (
<div className="container mt-5">
    <div className="card shadow-lg p-4 mb-4 rounded-3">
        <div className="card-header bg-primary text-white text-center">
            <h1 className="mb-0">{event.title}</h1>
        </div>
        <div className="card-body">
            <p><strong>Description:</strong> {event.description}</p>
            <p><strong>Date:</strong> {new Date(event.date).toLocaleString()}</p>
            <p><strong>Location:</strong> {event.location}</p>
            <p><strong>Mode:</strong> {event.mode}</p>
            <p><strong>Type:</strong> {event.type}</p>
            <p><strong>Created By:</strong> {event.created_by}</p>
            <p><strong>Verified:</strong> {event.is_verified ? 'Yes' : 'No'}</p>
            <p><strong>Created At:</strong> {new Date(event.created_at).toLocaleString()}</p>

            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-outline-primary" onClick={() => navigate('/EventList')}>
                    <i className="fas fa-arrow-left"></i> Back to Event List
                </button>
                {isOwner && (
                    <>
                        <button className="btn btn-outline-warning" onClick={() => setIsEditing(!isEditing)}>
                            {isEditing ? 'Cancel Edit' : 'Edit Event'} <i className="fas fa-edit"></i>
                        </button>
                        <button className="btn btn-outline-danger" onClick={handleDelete}>
                            Delete Event <i className="fas fa-trash"></i>
                        </button>
                    </>
                )}
            </div>

            {isEditing && (
                <form onSubmit={handleEditSubmit} className="mt-4">
                    <h2 className="text-primary">Edit Event</h2>
                    <div className="form-group mt-3">
                        <label>Title</label>
                        <input type="text" className="form-control" name="title" value={editedEvent.title} onChange={handleEditChange} required />
                    </div>
                    <div className="form-group mt-3">
                        <label>Description</label>
                        <textarea className="form-control" name="description" value={editedEvent.description} onChange={handleEditChange} required />
                    </div>
                    <div className="form-group mt-3">
                        <label>Date</label>
                        <input type="datetime-local" className="form-control" name="date" value={new Date(editedEvent.date).toISOString().slice(0, 16)} onChange={handleEditChange} required />
                    </div>
                    <div className="form-group mt-3">
                        <label>Location</label>
                        <input type="text" className="form-control" name="location" value={editedEvent.location} onChange={handleEditChange} required />
                    </div>
                    <div className="form-group mt-3">
                        <label>Mode</label>
                        <input type="text" className="form-control" name="mode" value={editedEvent.mode} onChange={handleEditChange} required />
                    </div>
                    <div className="form-group mt-3">
                        <label>Type</label>
                        <input type="text" className="form-control" name="type" value={editedEvent.type} onChange={handleEditChange} required />
                    </div>
                    <button type="submit" className="btn btn-success mt-4 w-100">Save Changes</button>
                </form>
            )}
        </div>
    </div>

    <div className="card shadow-lg p-4 rounded-3">
        <div className="card-header bg-secondary text-white">
            <h2 className="mb-0">Comments</h2>
        </div>
        <div className="card-body">
            <form onSubmit={handleCommentSubmit} className="mb-4">
                <div className="form-group">
                    <textarea className="form-control" value={newComment} onChange={handleCommentChange} placeholder="Leave a comment..." required />
                </div>
                <button type="submit" className="btn btn-primary mt-2 w-100">Post Comment</button>
            </form>
            <ul className="list-group">
                {comments.map((comment) => (
                    <li key={comment.id} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{comment.comment}</span>
                        {userId === comment.created_by && (
                            <div>
                                <button className="btn btn-sm btn-warning mr-2" onClick={() => handleEditComment(comment.id, comment.comment)}>Edit</button>
                                <button className="btn btn-sm btn-danger" onClick={() => handleDeleteComment(comment.id)}>Delete</button>
                            </div>
                        )}
                        {editedCommentId === comment.id && (
                            <form onSubmit={handleUpdateComment} className="mt-2">
                                <input type="text" className="form-control" value={editedCommentText} onChange={handleEditCommentChange} required />
                                <button type="submit" className="btn btn-success btn-sm mt-2 w-100">Update Comment</button>
                            </form>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    </div>
</div>

    );
};

export default EventDetails;
