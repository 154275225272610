import React from 'react';
import { Button } from 'react-bootstrap';

function ProfileHeader({ profileData, profileInfo, user, onEditProfileClick, setDisplayAddTemplate }) {
  return (
    <div
      className="profile-header text-white rounded-top shadow-sm"
      style={{
        background: 'black',
        padding: '2rem',
      }}
    >
      <div className="container py-4">
        <div className="row align-items-center">
          <div className="col-12 col-md-3 d-flex flex-column align-items-center mb-3 mb-md-0">
            <img
              alt="Profile"
              src={profileInfo?.profile.custom_profile_image || '/default-avatar.png'}
              className="img-fluid img-thumbnail rounded-circle border-0 shadow"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                transition: 'transform 0.3s ease',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            />
          </div>
          <div className="col-12 col-md-9 text-center text-md-left">
            <div className="d-flex flex-column justify-content-center h-100">
              <h2 className="mb-1" style={{ fontWeight: 'bold', letterSpacing: '1px' }}>
                {profileInfo?.profile.custom_username || 'Username'}
              </h2>
            <p className="text-light mb-3" style={{ fontStyle: 'italic', fontSize: '1.1rem', color: 'white' }}>
                            {profileInfo?.profile.bio && profileInfo.profile.bio.length > 100 
                              ? `${profileInfo.profile.bio.slice(0, 100)}...` 
                              : profileInfo?.profile.bio || 'This user has no bio.'}
                          </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
