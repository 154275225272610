import React, { useEffect, useState, useRef } from 'react';
import config from '../config';
import sendSound from '../assets/send.wav';
import receiveSound from '../assets/send.wav';
import applogo from '../imagecollection/applogo.png';
import Sentiment from 'sentiment'; // Import the sentiment analysis library

const ChatComponent = ({ masterId, supporterId }) => {
    const [rooms, setRooms] = useState([]);
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [messageQueue, setMessageQueue] = useState([]);
    const [unviewedCounts, setUnviewedCounts] = useState({});
    const messagesEndRef = useRef(null);

    const sendAudio = new Audio(sendSound);
    const receiveAudio = new Audio(receiveSound);
    const sentiment = new Sentiment(); // Create a sentiment instance

    useEffect(() => {
        const fetchData = async () => {
            await fetchChatRooms();
            const counts = await fetchUnviewedMessageCounts();
            setUnviewedCounts(counts);
        };

        fetchData();
    }, []);

    const fetchChatRooms = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/chat/chat-rooms/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRooms(removeDuplicates(data));
        } catch (error) {
            console.error('Error fetching chat rooms:', error);
        }
    };

    const removeDuplicates = (roomsArray) => {
        const seen = new Set();
        return roomsArray.filter((room) => {
            const username = room.other_user_image.custom_username;
            if (seen.has(username)) {
                return false;
            } else {
                seen.add(username);
                return true;
            }
        });
    };

    const fetchOldMessages = async (room) => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/chat/chat/messages/${room.id}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setMessages(data);
            await markMessagesAsViewed(room.id);
            createWebSocketConnection(room.master, room.supporter);
        } catch (error) {
            console.error('Error fetching old messages:', error);
        }
    };

    const markMessagesAsViewed = async (roomId) => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/chat/chat/messages/viewed/${roomId}/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark messages as viewed');
            }
        } catch (error) {
            console.error('Error marking messages as viewed:', error);
        }
    };

    const fetchUnviewedMessageCounts = async () => {
        try {
            const response = await fetch(`${config.API_ENDPOINT}/chat/chat/messages/unviewed/count/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch unviewed message counts');
            }

            const data = await response.json();
            return data; 
        } catch (error) {
            console.error('Error fetching unviewed message counts:', error);
            return {}; 
        }
    };

    const handleRoomClick = (room) => {
        setSelectedRoom(room);
        fetchOldMessages(room);
    };

    const createWebSocketConnection = (masterId, supporterId) => {
        const ws = new WebSocket(`${config.WEBSOCKET_ENDPOINT}/ws/chat/${masterId}/${supporterId}/?token=${localStorage.getItem('access_token')}`);

        ws.onopen = () => {
            messageQueue.forEach((message) => {
                ws.send(JSON.stringify(message));
            });
            setMessageQueue([]);
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, data]);
            receiveAudio.play(); // Play receive sound on new message
        };

        setSocket(ws);
    };

    const sendMessage = () => {
        if (newMessage.trim() === '' || !socket || socket.readyState !== WebSocket.OPEN) {
            return;
        }

        const messageData = {
            message: newMessage,
            room_id: selectedRoom.id,
        };

        socket.send(JSON.stringify(messageData));
        sendAudio.play(); // Play send sound on message sent
        setNewMessage('');
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Function to determine the emoji based on the sentiment score
    const getEmojiBySentiment = (text) => {
      const result = sentiment.analyze(text);
      const score = result.score;
  
      // Define reactions based on sentiment score ranges
      if (score > 15) {
          return '😍'; // Extremely positive
      } else if (score > 10) {
          return '😊'; // Very positive
      } else if (score > 5) {
          return '😃'; // Positive
      } else if (score > 0) {
          return '🙂'; // Slightly positive
      } else if (score === 0) {
          return '😐'; // Neutral
      } else if (score > -5) {
          return '😕'; // Slightly negative
      } else if (score > -10) {
          return '😞'; // Negative
      } else if (score > -15) {
          return '😢'; // Very negative
      } else if (score > -20) {
          return '😭'; // Extremely negative
      } else if (score > -25) {
          return '🤯'; // Shocked
      } else if (score > -30) {
          return '😩'; // Frustrated
      } else if (score > -35) {
          return '😤'; // Annoyed
      } else if (score > -40) {
          return '😠'; // Angry
      } else if (score > -45) {
          return '😡'; // Very angry
      } else if (score > -50) {
          return '🤬'; // Extremely angry
      } else if (score > -55) {
          return '🤔'; // Confused
      } else if (score > -60) {
          return '😳'; // Surprised
      } else if (score > -65) {
          return '🤗'; // Hugs
      } else if (score > -70) {
          return '😵'; // Dizzy
      } else {
          return '💔'; // Heartbroken
      }
  };

    return (
        <section>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                        <h5 className="font-weight-bold mb-3 text-center text-lg-start">Chat Rooms</h5>
                        <div className="card">
                            <div className="card-body">
                                <ul className="list-unstyled mb-0">
                                    {rooms.length > 0 ? (
                                        rooms.map((room, index) => (
                                            <li key={index} className="p-2 border-bottom" onClick={() => handleRoomClick(room)}>
                                                <a href="#!" className="d-flex justify-content-between">
                                                    <div className="d-flex flex-row">
                                                        <div className="pt-1">
                                                            <p className="fw-bold mb-0">
                                                                {room.other_user_image.custom_username.length > 15
                                                                    ? `${room.other_user_image.custom_username.substring(0, 15)}...`
                                                                    : room.other_user_image.custom_username}
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                {room.other_user_image.bio
                                                                    ? room.other_user_image.bio.length > 50
                                                                        ? `${room.other_user_image.bio.substring(0, 50)}...`
                                                                        : room.other_user_image.bio
                                                                    : 'Bio not updated'}
                                                            </p>
                                                            {unviewedCounts[room.id] > 0 && (
                                                                <span className="badge bg-danger">{unviewedCounts[room.id]} new</span>
                                                            )}
                                                        </div>
                                                        {room.other_user_image && (
                                                            <img
                                                                src={room.other_user_image.custom_profile_image || room.other_user_image.profile_image}
                                                                alt={room.other_user_image.custom_username}
                                                                style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px' }}
                                                            />
                                                        )}
                                                    </div>
                                                </a>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="p-2">No chat rooms available.</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-7 col-xl-8">
                    {selectedRoom && (
                        <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                            <h3 className="mb-4">Messages in Room ID: {selectedRoom.id}</h3>
                            <ul className="list-unstyled">
    {messages.length > 0 ? (
        messages.map((msg, index) => (
            <li key={index} className="d-flex align-items-start mb-4">
                {msg.custom_profile_image ? (
                    <img
                        src={msg.custom_profile_image}
                        alt={msg.sender_username || 'User'}
                        className="rounded-circle me-2"
                        style={{ width: '40px', height: '40px' }}
                    />
                ) : (
                    <span className="me-2" style={{ fontSize: '40px' }}>
                        {getEmojiBySentiment(msg.message)} {/* Use the function to get the emoji */}
                    </span>
                )}
                <div>
                    {msg.sender_username ? (
                        <>
                            <strong>{msg.sender_username}:</strong>
                            <span className={`ms-2 ${sentiment.analyze(msg.message).score > 0 ? 'text-success' : sentiment.analyze(msg.message).score < 0 ? 'text-danger' : 'text-muted'}`}>
                                {msg.message}
                            </span>
                        </>
                    ) : (
                        <div className="rounded bg-light p-2 d-inline-block">
                            <span className="text-muted">
                                {msg.message}
                            </span>
                        </div>
                    )}
                </div>
            </li>
        ))
    ) : (
        <li>No messages available.</li>
    )}
    <div ref={messagesEndRef} />
</ul>

                            <div className="bg-white mb-3">
                                <div className="form-outline">
                                    <textarea
                                        className="form-control bg-body-tertiary"
                                        id="textAreaExample2"
                                        rows="4"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        placeholder="Type your message..."
                                    />
                                    <label className="form-label" htmlFor="textAreaExample2">Message</label>
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={sendMessage}
                            >
                                Send
                            </button>
                        </div>
                    )}
                </div>


                </div>
            </div>
        </section>
    );
};

export default ChatComponent;
