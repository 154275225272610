import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import loading_image from '../imagecollection/giphy.webp';

const ProfileCard = ({
  userFollowed,
  setUserFollowed,
  searchTerm, // Add searchTerm as a prop from the parent component
}) => {
  const email = localStorage.getItem("googleEmail")
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [runApi, setRunApi] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  
  const navigate = useNavigate();

  const getAuthHeaders = () => {
    const token = localStorage.getItem('access_token');
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  const fetchUserStats = async (userId) => {
    try {
      const response = await axios.get(
        `${config.API_ENDPOINT}/follow/user/${userId}/stats/`,
        { headers: getAuthHeaders() }
      );
      const stats = response.data;
  
      
      setProfiles((prevProfiles) =>
        prevProfiles.map((profile) =>
          profile.user_id === userId
            ? {
                ...profile,
                follower_count: stats.followers_count,
                following_count: stats.following_count,
                supports_count: stats.supports_count,
              }
            : profile
        )
      );
    } catch (err) {
      console.error('Error fetching user stats:', err);
    }
  };
  const fetchFollowing = async (userId) => {
    try {
      const response = await axios.get(
        `${config.API_ENDPOINT}/follow/followers/${userId}/`,
        { headers: getAuthHeaders() }
      );
      const followers = response.data;
      
      const isFollowed = followers.some((follower) => follower.email === email);

      setProfiles((prevProfiles) =>
        prevProfiles.map((profile) =>
          profile.user_id === userId
            ? { ...profile, isFollowed }
            : profile
        )
      );
    } catch (error) {
      console.error("There was an error fetching followers!", error);
    }
  };

  const userCanChat = async(profileId) => {
    try {
      const response = await axios.get(
        `${config.API_ENDPOINT}/follow/can-chat/${profileId}/2`,
        { headers: getAuthHeaders() }
      );
     
      
    } catch (err) {
      console.error('Error fetching chat permission:', err);
    }
  };

  const fetchProfiles = async (url) => {

    setLoading(true);

    
    try {
      const response = await axios.get(url, { headers: getAuthHeaders() });
     
      setProfiles(response.data.results);
      setNextPageUrl(response.data.next);
      setPrevPageUrl(response.data.previous);
      response?.data.results.forEach((profile) => {
        fetchUserStats(profile.user_id);
        fetchFollowing(profile.user_id);
        userCanChat(profile.user_id);
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = searchTerm
      ? `${config.API_ENDPOINT}/api/sorted-profiles?username=${searchTerm}`
      : `${config.API_ENDPOINT}/api/sorted-profiles/`;

    fetchProfiles(url);
  }, [searchTerm]);

  const handleNextPage = () => {
    if (nextPageUrl) {
      const url = searchTerm 
        ? `${nextPageUrl}&username=${searchTerm}` 
        : nextPageUrl;
      fetchProfiles(url);
    }
  };
  const handlePrevPage = () => {
    if (prevPageUrl) {
      const url = searchTerm 
        ? `${prevPageUrl}&username=${searchTerm}` 
        : prevPageUrl;
      fetchProfiles(url);
    }
  };

  const followUser = (userId) => {
    if (!userId) return;
    // setRunApi(!runApi);

    return axios
      .post(`${config.API_ENDPOINT}/follow/create_follow/${userId}/`, {}, { headers: getAuthHeaders() })
      .then((response) => {
        setProfiles((prevProfiles) =>
          prevProfiles.map((profile) =>
            profile.user_id === userId
              ? { ...profile, follower_count: profile.follower_count + 1 }
              : profile
          )
        );
        setUserFollowed((prevState) => [...prevState, userId]);
      })
      .catch((error) => {
        console.error('Error Following:', error);
        alert(error.response.data.error);
      });
  };

  const handleFollowClick = (userId) => {
    followUser(userId);
  };

  const handleUnfollowClick = (userId) => {
    // setRunApi(!runApi);
 
    axios
      .post(`${config.API_ENDPOINT}/follow/unfollow/${userId}/`, {}, { headers: getAuthHeaders() })
      .then(() => {
        setProfiles((prevProfiles) =>
          prevProfiles.map((profile) =>
            profile.user_id === userId
              ? { ...profile, follower_count: profile.follower_count - 1 }
              : profile
          )
        );
        setUserFollowed((prevState) => prevState.filter((id) => id !== userId));
      })
      .catch((error) => {
        console.error('Error unfollowing:', error);
      });
  };

  const handleClick = async (profile) => {
    try {
      // Retrieve master_id from local storage
      const masterId = localStorage.getItem('user_id_data'); // Replace with the actual key if it's different
  
      // Ensure the URL is formed correctly
      const apiUrl = `${config.API_ENDPOINT}/chat/api/chat/create-room/`;
  
      // Prepare the request data
      const requestData = {
        master_id: masterId, // Use the masterId from local storage
        supporter_id: profile.user_id, // Assuming the profile's user_id is the supporter
      };
  
      // Log request payload and headers for debugging
      console.log("Request URL:", apiUrl);
      console.log("Request Headers:", getAuthHeaders());
      console.log("Request Payload:", requestData);
  
      // Make the POST request to create or get the chat room
      const response = await axios.post(apiUrl, requestData, { headers: getAuthHeaders() });
  
      // Log the full response data for debugging
      console.log("Response Data:", response.data);
  
      // Check if a chat room was successfully created or already exists
      if (response.status === 201) {
        const roomData = response.data;
  
        console.log("Chat room created successfully with data:", roomData);
  
        // Navigate to the ChatComponent with the room data
        navigate('/ChatComponent', {
          state: {
            roomId: roomData.id, // ID of the chat room created
            profileId: profile.user_id,
            profileName: profile.name,
            profilePicture: profile.picture,
            familyName: profile.family_name,
            followerCount: profile.follower_count,
            followingCount: profile.following_count,
            supportsCount: profile.supports_count,
            canChat: profile.canChat,
            roomName: roomData.room_name, // Chat room name
          }
        });
      } else if (response.status === 200) {
        // Handle the scenario if the room already exists (Assuming 200 is returned if the room exists)
        const existingRoomData = response.data;
        console.log("Chat room already exists:", existingRoomData);
  
        // Redirect the user to the existing chat room
        navigate('/ChatComponent', {
          state: {
            roomId: existingRoomData.id, // ID of the existing chat room
            profileId: profile.user_id,
            profileName: profile.name,
            profilePicture: profile.picture,
            familyName: profile.family_name,
            followerCount: profile.follower_count,
            followingCount: profile.following_count,
            supportsCount: profile.supports_count,
            canChat: profile.canChat,
            roomName: existingRoomData.room_name, // Chat room name
          }
        });
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      // Log the full error message and response for debugging
      console.error('Error creating or accessing chat room:', error);
      console.error('Error Response:', error.response?.data);
    }
  };
  
  

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
        <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  
  if (error) {
    return <p>Error loading profiles: {error.message}</p>;
  }
  

  return (
<div className="container">
  <div className="row">
    {profiles?.map((profile) => (
      <div
        key={profile.user_id}
        className="col-12 col-sm-6 col-md-4 mb-4 d-flex justify-content-center"
      >
        <div className="card shadow-lg" style={{ borderRadius: '20px', width: '100%', overflow: 'hidden' }}>
          <div className="card-body text-center">
            <img
              onClick={() => navigate(`/profile/${profile.user_id}`)}
              src={profile.custom_profile_image || 'default-avatar.png'} // Fallback image
              alt="Profile"
              className="img-fluid"
              style={{
                cursor: 'pointer',
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                border: '3px solid #007bff',
                boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
              }}
            />
            <h5 className="mt-3 text-primary font-weight-bold">{profile.custom_username}</h5>
            <p className="text-muted">{profile.family_name}</p>

            {profile.bio && profile.bio.length > 0 && (
              <p className="mt-2 text-secondary" style={{ fontSize: '0.9rem', lineHeight: '1.4' }}>
                {profile.bio.length > 250 ? `${profile.bio.slice(0, 250)}...` : profile.bio}
              </p>
            )}

            <div className="d-flex justify-content-around w-100 mb-3">
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/FollowersList/${profile.user_id}`)}
              >
                <p className="small text-muted mb-1">Followers</p>
                <p className="mb-0 font-weight-bold">{profile.follower_count}</p>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/FollowingList/${profile.user_id}`)}
              >
                <p className="small text-muted mb-1">Following</p>
                <p className="mb-0 font-weight-bold">{profile.following_count}</p>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/service-agreement/${profile.user_id}`)}
              >
                <p className="small text-muted mb-1">Supports</p>
                <p className="mb-0 font-weight-bold">{profile.supports_count}</p>
              </div>
            </div>

            <div className="w-100 mb-3 text-center">
          <button
            type="button"
            className={`btn w-100 ${profile.isFollowed ? 'btn-danger' : 'btn-primary'} fw-bold py-2 px-4 rounded-pill`}
            onClick={() =>
              profile.isFollowed ? handleUnfollowClick(profile.user_id) : handleFollowClick(profile.user_id)
            }
          >
            {profile.isFollowed ? 'Unfollow' : 'Follow'}
          </button>

          <button
            key={profile.id}
            onClick={() => handleClick(profile)}
            className="btn btn-outline-primary mt-3 fw-semibold rounded-pill py-2 px-4"
          >
            Chat with {profile.custom_username}
          </button>
        </div>

          </div>
        </div>
      </div>
    ))}
  </div>

  {/* Pagination controls */}
  <nav className="d-flex justify-content-center align-items-center my-4">
    <button
      className="btn btn-outline-primary me-2"
      onClick={handlePrevPage}
      disabled={!prevPageUrl}
      style={{ borderRadius: '20px', fontWeight: 'bold' }}
    >
      Previous
    </button>
    <button
      className="btn btn-outline-primary"
      onClick={handleNextPage}
      disabled={!nextPageUrl}
      style={{ borderRadius: '20px', fontWeight: 'bold' }}
    >
      Next
    </button>
  </nav>
</div>
  );
};

export default ProfileCard;
