// src/PayPalInfo.js
import React, { useState, useEffect } from 'react';
import config from '../config';

const PayPalInfo = () => {
    const [paypalEmail, setPayPalEmail] = useState('');
    const [fetchError, setFetchError] = useState(null);
    const [message, setMessage] = useState('');
    const [hasPayPalInfo, setHasPayPalInfo] = useState(false);
    const [emailError, setEmailError] = useState('');

    // Function to get token from local storage
    const getToken = () => {
        return localStorage.getItem('access_token'); // Adjust 'access_token' to match your token key
    };

    // Validate email format
    const validateEmail = (email) => {
        // Basic email regex for validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Fetch PayPal info on component mount
    useEffect(() => {
        const fetchPayPalInfo = async () => {
            const token = getToken();
            if (!token) {
                setFetchError('No authentication token found');
                return;
            }

            try {
                const response = await fetch(`${config.API_ENDPOINT}/get_paypal_info/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch PayPal info');
                }
                const data = await response.json();
                setPayPalEmail(data.paypal_email || '');
                setHasPayPalInfo(!!data.paypal_email); // Set state based on whether info exists
            } catch (error) {
                setFetchError('Error fetching PayPal info');
            }
        };

        fetchPayPalInfo();
    }, []);

    const handleSetPayPalInfo = async () => {
        if (!validateEmail(paypalEmail)) {
            setEmailError('Invalid email format');
            return;
        }
        setEmailError(''); // Clear error if email is valid

        const token = getToken();
        if (!token) {
            setMessage('No authentication token found');
            return;
        }

        try {
            const response = await fetch(`${config.API_ENDPOINT}/set_paypal_info/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ paypal_email: paypalEmail })
            });
            if (!response.ok) {
                throw new Error('Failed to set PayPal info');
            }
            const data = await response.json();
            setPayPalEmail(data.paypal_info.paypal_email);
            setHasPayPalInfo(true);
            setMessage(`PayPal info created successfully: ${data.paypal_info.paypal_email}`);
        } catch (error) {
            setMessage('Error setting PayPal info');
        }
    };

    const handleUpdatePayPalInfo = async () => {
        if (!validateEmail(paypalEmail)) {
            setEmailError('Invalid email format');
            return;
        }
        setEmailError(''); // Clear error if email is valid

        const token = getToken();
        if (!token) {
            setMessage('No authentication token found');
            return;
        }

        try {
            const response = await fetch(`${config.API_ENDPOINT}/update_paypal_info/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ paypal_email: paypalEmail })
            });
            if (!response.ok) {
                throw new Error('Failed to update PayPal info');
            }
            const data = await response.json();
            setPayPalEmail(data.paypal_info.paypal_email);
            setMessage(`PayPal info updated successfully: ${data.paypal_info.paypal_email}`);
        } catch (error) {
            setMessage('Error updating PayPal info');
        }
    };

    const handleDeletePayPalInfo = async () => {
        const token = getToken();
        if (!token) {
            setMessage('No authentication token found');
            return;
        }

        try {
            const response = await fetch(`${config.API_ENDPOINT}/delete_paypal_info/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete PayPal info');
            }
            setPayPalEmail('');
            setHasPayPalInfo(false);
            setMessage('PayPal info deleted successfully');
        } catch (error) {
            setMessage('Error deleting PayPal info');
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Manage PayPal Information</h1>
            <div className="mb-3">
                <label htmlFor="paypalEmail" className="form-label">PayPal Email</label>
                <input
                    id="paypalEmail"
                    type="email"
                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                    value={paypalEmail}
                    onChange={(e) => setPayPalEmail(e.target.value)}
                    placeholder="Enter PayPal email"
                />
                {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>
            <div className="mb-3">
                {!hasPayPalInfo && (
                    <button
                        className="btn btn-primary me-2"
                        onClick={handleSetPayPalInfo}
                    >
                        Set PayPal Info
                    </button>
                )}
                {hasPayPalInfo && (
                    <>
                        <button
                            className="btn btn-warning me-2"
                            onClick={handleUpdatePayPalInfo}
                        >
                            Update PayPal Info
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={handleDeletePayPalInfo}
                        >
                            Delete PayPal Info
                        </button>
                    </>
                )}
            </div>

            {/* Conditional Rendering for Messages */}
            {fetchError && (
                <div className="alert alert-danger" role="alert">
                    {fetchError}
                </div>
            )}
            {message && (
                <div className="alert alert-success" role="alert">
                    {message}
                </div>
            )}
        </div>
    );
};

export default PayPalInfo;
