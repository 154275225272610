import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';

function UpdateCustomUsername() {
    const [customUsername, setCustomUsername] = useState('');
    const [customProfileImage, setCustomProfileImage] = useState(null);
    const [bio, setBio] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch the user's current custom_username, custom_profile_image, and bio on component mount
        const fetchUserProfile = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await axios.get(`${config.API_ENDPOINT}/get-user-with-image/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCustomUsername(response.data.custom_username || '');
                setCustomProfileImage(response.data.custom_profile_image || '');
                setBio(response.data.bio || '');
            } catch (err) {
                console.error('Error fetching user profile:', err);
            }
        };

        fetchUserProfile();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        const formData = new FormData();
        formData.append('custom_username', customUsername);
        if (customProfileImage) {
            formData.append('image', customProfileImage);
        }
        formData.append('bio', bio);

        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.put(`${config.API_ENDPOINT}/update-user-profile/`, 
            formData, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message);
        } catch (err) {
            console.error('Error updating profile:', err);
            setError(err.response?.data?.error || 'An error occurred');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="col-md-6 col-lg-5 p-4 shadow-sm bg-light rounded">
                <h2 className="mb-4 text-center">Update Profile</h2>
                {message && <div className="alert alert-success text-center">{message}</div>}
                {error && <div className="alert alert-danger text-center">{error}</div>}

                <form onSubmit={handleUpdate}>
                    <div className="form-group mb-3">
                        <label htmlFor="customUsername" className="form-label">Custom Username</label>
                        <input
                            type="text"
                            id="customUsername"
                            className="form-control form-control-lg"
                            value={customUsername}
                            onChange={(e) => setCustomUsername(e.target.value)}
                            placeholder="Enter your custom username"
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="customProfileImage" className="form-label">Profile Image</label>
                        <input
                            type="file"
                            id="customProfileImage"
                            className="form-control form-control-lg"
                            onChange={(e) => setCustomProfileImage(e.target.files[0])}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="bio" className="form-label">Bio</label>
                        <textarea
                            id="bio"
                            className="form-control form-control-lg"
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                            placeholder="Enter your bio"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100 btn-lg">Update</button>
                </form>
            </div>
        </div>
    );
}

export default UpdateCustomUsername;
