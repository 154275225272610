import React from 'react';

const ContractPreview = ({ formData, onConfirm, onEdit }) => {
    return (
        <div>
            <h3 className="text-center text-info">Contract Preview</h3>
            <div className="border p-4 rounded bg-light">
                <h5 className="text-center mb-4">Mistress-Slave Contract</h5>
                <div className="mb-3">
                    <p><strong>Mistress Name:</strong> {formData.teacherName}</p>
                    <p><strong>About Mistress:</strong> {formData.aboutTeacher}</p>
                    <p><strong>Slave Name:</strong> {formData.studentName}</p>
                    <p><strong>Requirement:</strong> {formData.requirement}</p>
                    <p><strong>Tasks:</strong> {formData.tasks}</p>
                    <p><strong>Time Period:</strong> {formData.timePeriod}</p>
                    <p><strong>Contract Fee:</strong> {formData.contractFee}</p>
                </div>
                <div className="mt-4 border-top pt-3">
                    <p><strong>Signatures:</strong></p>
                    <p><strong>Mistress:</strong></p>
                    <p>Signature: ___________________________</p>
                    <p>Date: ___________________________</p>
                    <p><strong>Slave:</strong></p>
                    <p>Signature: ___________________________</p>
                    <p>Date: ___________________________</p>
                </div>
                <button className="btn btn-success btn-block mt-4" onClick={onConfirm}>
                    Confirm Contract
                </button>
                <button className="btn btn-secondary btn-block mt-2" onClick={onEdit}>
                    Edit
                </button>
            </div>
        </div>
    );
};

export default ContractPreview;
