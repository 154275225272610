import React from 'react';
import axios from 'axios';
import config from '../config';
import logo from '../imagecollection/applogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const PayPalButton = ({ charges }) => {
    const handlePayment = async () => {
        if (charges !== "") {
            try {
                const response = await axios.get(`${config.API_ENDPOINT}/payments/api/paypal/create/?total_amount=${charges}`);
                const { approval_url } = response.data;
                window.location.href = approval_url;
            } catch (error) {
                console.error('Payment error:', error);
            }
        }
    };

    return (
        <div className="container text-center my-5">
            <div className="card shadow-lg p-4 mb-4 bg-white rounded">
                <div className="card-body">
                    <img src={logo} alt="Payment illustration" className="img-fluid mb-3" style={{ maxWidth: '150px' }} />
                    <h3 className="card-title mb-3">Complete Your Payment</h3>
                    <p className="card-text mb-4">Secure and easy payment process with PayPal.</p>
                    <button 
                        className="btn btn-primary btn-lg"
                        onClick={handlePayment}
                    >
                        <i className="fab fa-paypal"></i> Pay with PayPal
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PayPalButton;
