import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import './AdminPage.css'; // Import custom CSS for additional styling

function AdminPage() {
    const [hasTemplate, setHasTemplate] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('access_token');
                if (!token) {
                    throw new Error('Token is missing');
                }
    
                // Get user ID
                const userResponse = await axios.get(`${config.API_ENDPOINT}/get-user/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(userResponse.data.user_id);
    
                // Check if the user has a contract template
                const templateResponse = await axios.get(`${config.API_ENDPOINT}/contracts/contract-template/check/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setHasTemplate(templateResponse.data.has_template);
            } catch (error) {
                console.error('Error fetching user data or contract template status:', error);
            }
        };
    
        fetchUserData();
    }, []);

    return (
        <div className="admin-page-content">
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-4 shadow-sm">
                <div className="container-fluid">
                    <span className="navbar-brand">Admin Dashboard</span>
                </div>
            </nav>

            {/* Page Content */}
            <div className="container">
                <h2 className="mb-4 text-center text-primary">Welcome to the Dashboard</h2>
                <p className="text-center mb-4">
                    Here you can manage users, view settings, and perform other administrative tasks.
                </p>

                <div className="d-flex flex-column align-items-center">
                    <div className="card mb-3 p-3 shadow-sm border-light">
                        <Link to="/VideoApproval" className="btn btn-primary mb-3 px-4 py-2 rounded-pill shadow-lg">
                            <i className="bi bi-check-circle me-2"></i> Video Approval
                        </Link>
                        <Link to="/TeacherUploadVideoadmin" className="btn btn-secondary mb-3 px-4 py-2 rounded-pill shadow-lg">
                            <i className="bi bi-upload me-2"></i> Mistress Upload Video Admin
                        </Link>
                        <Link to="/PaymentDetailsPage" className="btn btn-secondary mb-3 px-4 py-2 rounded-pill shadow-lg">
                            <i className="bi bi-credit-card me-2"></i> Payment Details Page
                        </Link>

                        <Link to="/update-username" className="btn btn-secondary mb-3 px-4 py-2 rounded-pill shadow-lg">
                            <i className="bi bi-info-circle me-2"></i> Update Profile
                        </Link>
                        
                        <Link to="/PayPalInfo" className="btn btn-secondary mb-3 px-4 py-2 rounded-pill shadow-lg">
                            <i className="bi bi-info-circle me-2"></i> PayPal Info
                        </Link>

                        {/* Conditionally show contract template links if the user has a template */}
                        {hasTemplate ? (
                            <Link to={`/contracts/contract-templates/${userId}`} className="btn btn-secondary px-4 py-2 rounded-pill shadow-lg">
                                <i className="bi bi-file-earmark-text me-2"></i> Edit Contract Template
                            </Link>
                        ) : (
                            <Link to="/AddTemplateAdmin" className="btn btn-secondary px-4 py-2 rounded-pill shadow-lg">
                                <i className="bi bi-file-earmark-plus me-2"></i> Create Template for Contract
                            </Link>
                        )}
                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminPage;
