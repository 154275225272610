import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './VideoPage.css'; // Import custom CSS for additional styling
import config from '../config';

const VideoPage = () => {
  const { id } = useParams();
  const [videoDetails, setVideoDetails] = useState(null);

  const token = localStorage.getItem('access_token');
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await axios.get(`${config.API_ENDPOINT}/video/video/${id}/`, { headers });
        setVideoDetails(response.data);
      } catch (error) {
        console.error('Error fetching video details', error);
      }
    };

    fetchVideoDetails();
  }, [id]);

  if (!videoDetails) {
    return <div className="text-center mt-4">Loading video details...</div>;
  }

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="video-card shadow-lg mb-4">
            <div className="video-card-header">
              <h1 className="video-title">{videoDetails.title}</h1>
            </div>
            <div className="video-content">
              <div className="video-wrapper">
                <video className="video-player" controls>
                  <source src={videoDetails.file} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="video-info">
                <p><strong>Description:</strong> {videoDetails.description}</p>
                <p><strong>Location:</strong> {videoDetails.location}</p>
                <p><strong>Uploader:</strong> {videoDetails.uploader}</p>
                <p><strong>Tags:</strong> {videoDetails.tags.map(tag => tag.name).join(', ')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
