// src/components/EventList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config';


const EventList = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${config.API_ENDPOINT}/event_host/events/`);
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div className="container my-4">
            <h1 className="text-center mb-4">Event List</h1>
            <div className="row">
                {events.map(event => (
                    <div key={event.id} className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="card shadow-sm h-100">
                            <div className="card-body">
                                <Link to={`/events/${event.id}`} className="text-decoration-none">
                                    <h5 className="card-title text-primary">{event.title}</h5>
                                </Link>
                                <p className="card-text"><strong>Date:</strong> {new Date(event.date).toLocaleString()}</p>
                                <p className="card-text"><strong>Location:</strong> {event.location || 'Location not specified'}</p>
                                <p className="card-text"><strong>Description:</strong> {event.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventList;
