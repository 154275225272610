import React, { useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import './SuccessPage.css';  // Importing the CSS file

const SuccessPage = () => {
    const userId = localStorage.getItem("userId");
    const masterId = localStorage.getItem("masterId");
    const charge = localStorage.getItem("charge");

    const navigate = useNavigate();

    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    const handlePaymentApi = async () => {
        const postData = {
            user: userId,
            master: masterId,
            amount: charge,
            currency: "USD",
            status: "pending",
            payment_method: "credit_card",
            expiration_days: 10,
        };
        try {
            const responsePayment = await axios.post(`${config.API_ENDPOINT}/payments/payments/`, postData, { headers: getAuthHeaders() });
         
        } catch (error) {
            console.error('Error in payment API:', error);
        }
    };

    const handleSupportApi = async () => {
        const supportData = {
            master_id: masterId,
            supporter_id: userId
        };
        try {
            const responseSupport = await axios.post(`${config.API_ENDPOINT}/follow/support/`, supportData, { headers: getAuthHeaders() });
   
        } catch (error) {
            console.error('Error in support API:', error);
        }
    };

    useEffect(() => {
        const executePayment = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const paymentId = urlParams.get('paymentId');
            const payerId = urlParams.get('PayerID');

            try {
                await axios.get(`${config.API_ENDPOINT}/payments/api/payment/execute/?paymentId=${paymentId}&PayerID=${payerId}`);
                
                await handlePaymentApi();
                await handleSupportApi();
                
                localStorage.removeItem("userId");
                localStorage.removeItem("masterId");
                localStorage.removeItem("charge");

                // alert('Payment successful');
                navigate('/PaymentSuccessPage');
            } catch (error) {
                console.error('Payment execution error:', error);
            }
        };

        executePayment();
    }, [navigate]);

    return (
        <div className="success-page">
            <div className="loader"></div>
            <h1>Processing your payment...</h1>
            <p>Please wait while we complete your transaction.</p>
        </div>
    );
};

export default SuccessPage;
