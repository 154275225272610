// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import './Chat.css'; // Import your CSS file for styles
// import config from '../config';

// const Chat = () => {
//   const location = useLocation();
//   const {
//     profileId,
//     profileName,
//     profilePicture,
//     familyName,
//   } = location.state || {};

//   // State to store chat messages, user ID, and profile image
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');
//   const [userId, setUserId] = useState(null);
//   const [userImage, setUserImage] = useState(''); // State to store user profile image

//   // WebSocket reference
//   const ws = useRef(null);

//   // Function to get access token from localStorage
//   const getAuthHeaders = () => {
//     const token = localStorage.getItem('access_token'); // Replace 'access_token' with the correct key for your token
//     return {
//       Authorization: `Bearer ${token}`,
//     };
//   };

//   // Fetch user ID and profile image
//   useEffect(() => {
//     const fetchUserInfo = async () => {
//       try {
//         const response = await axios.get(`${config.API_ENDPOINT}/get-user-with-image/`, {
//           headers: getAuthHeaders(),
//         });
//         setUserId(response.data.id);
//         setUserImage(response.data.profile_image); // Set the profile image from the response
//       } catch (error) {
//         console.error('Error fetching user info:', error);
//       }
//     };

//     fetchUserInfo();
//   }, []);

//   // Establish WebSocket connection when component mounts
//   useEffect(() => {
//     if (!userId || !profileId) return;

//     // Create a consistent room name by sorting user IDs
//     const roomName = [profileId, userId].sort().join('_and_');
//     const token = localStorage.getItem('access_token');
//     ws.current = new WebSocket(`ws://localhost:8000/ws/chat/${roomName}/?token=${token}`);

//     // Handle incoming messages
//     ws.current.onmessage = (event) => {
//       const messageData = JSON.parse(event.data);
//       setMessages((prevMessages) => [...prevMessages, messageData]);
//     };

//     // Handle WebSocket connection closure
//     ws.current.onclose = (event) => {
//       console.log('WebSocket connection closed:', event);
//     };

//     return () => {
//       // Close the WebSocket connection when component unmounts
//       if (ws.current) {
//         ws.current.close();
//       }
//     };
//   }, [profileId, userId]);

//   // Fetch chat messages from the API
//   useEffect(() => {
//     const fetchMessages = async () => {
//       try {
//         const response = await axios.get(`${config.API_ENDPOINT}/chat/chat/messages/${profileId}/${userId}/`, {
//           headers: getAuthHeaders(),
//         });
//         setMessages(response.data);
//       } catch (error) {
//         console.error('Error fetching chat messages:', error);
//       }
//     };

//     if (userId && profileId) {
//       fetchMessages();
//     }
//   }, [profileId, userId]);

//   // Handle sending a message through WebSocket
//   const handleSendMessage = () => {
//     if (!newMessage.trim()) return;

//     const messageData = {
//       message: newMessage,
//       sender: userId,
//       created_at: new Date().toISOString(),
//     };

//     // Send the message through WebSocket
//     if (ws.current && ws.current.readyState === WebSocket.OPEN) {
//       ws.current.send(JSON.stringify(messageData));
//       setMessages((prevMessages) => [...prevMessages, messageData]); // Add to local messages
//       setNewMessage(''); // Clear the input field
//     }
//   };

//   return (
//     <section>
//       <div className="container py-5">
//         <div className="row d-flex justify-content-center">
//           <div className="col-md-10 col-lg-8 col-xl-6">
//             <div className="card" id="chat2">
//               <div className="card-header d-flex justify-content-between align-items-center p-3">
//                 <h5 className="mb-0">Chat with {profileName} {familyName}</h5>
//                 <button type="button" className="btn btn-primary btn-sm">
//                   Let's Chat App
//                 </button>
//               </div>
//               <div className="card-body" style={{ position: 'relative', height: '400px', overflowY: 'scroll' }}>
//                 {messages.map((message, index) => (
//                   <div key={index} className={`d-flex flex-row justify-content-${message.sender === userId ? 'end' : 'start'} mb-4`}>
//                     {message.sender !== userId && (
//                       <img src={profilePicture} alt={`${profileName}'s avatar`} style={{ width: '45px', height: '100%' }} />
//                     )}
//                     <div>
//                       <p className={`small p-2 me-3 mb-1 rounded-3 ${message.sender === userId ? 'bg-primary text-white' : 'bg-body-tertiary'}`}>
//                         {message.message}
//                       </p>
//                       <p className="small text-muted d-flex justify-content-end">{new Date(message.created_at).toLocaleTimeString()}</p>
//                     </div>
//                     {message.sender === userId && (
//                       <img src={userImage} alt="Your avatar" style={{ width: '45px', height: '100%' }} />
//                     )}
//                   </div>
//                 ))}
//               </div>
//               <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
//                 <textarea
//                   className="form-control form-control-lg"
//                   rows="1"
//                   placeholder="Type your message..."
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-primary btn-lg"
//                   onClick={handleSendMessage}
//                 >
//                   Send
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Chat;


// ChatComponent.js
import React, { useEffect, useState } from 'react';
import config from '../config';
import ChatRoomList from './ChatRoomList';
import ChatRoom from './ChatRoom';

const ChatComponent = ({ masterId, supporterId }) => {
  const [rooms, setRooms] = useState([]);
  const [socket, setSocket] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [messageQueue, setMessageQueue] = useState([]);

  useEffect(() => {
    console.log("Fetching chat rooms...");
    fetchChatRooms();
  }, []);

  const fetchChatRooms = async () => {
    try {
      console.log("Making API call to fetch chat rooms...");
      const response = await fetch(`${config.API_ENDPOINT}/chat/chat-rooms/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        console.error('Network response was not ok. Status:', response.status, response.statusText);
        throw new Error('Failed to fetch chat rooms');
      }

      const data = await response.json();
      console.log("Chat rooms fetched successfully:", data);
      setRooms(data);
    } catch (error) {
      console.error('Error fetching chat rooms:', error);
    }
  };

  const handleRoomClick = (room) => {
    console.log("Clicked room:", room);
    if (socket) {
      console.log("Closing existing WebSocket connection...");
      socket.close(); // Close existing socket
    }

    setSelectedRoom(room);
    console.log(`Selected room set. Creating new WebSocket connection for master ID: ${room.master} and supporter ID: ${room.supporter}`);
    createWebSocketConnection(room.master, room.supporter);
  };

  const createWebSocketConnection = (masterId, supporterId) => {
    if (!masterId || !supporterId) {
        console.error("Cannot create WebSocket connection. IDs are undefined:", masterId, supporterId);
        return;
    }

    const token = localStorage.getItem('access_token');
    const websocketURL = `${config.WEBSOCKET_ENDPOINT}/ws/chat/${masterId}/${supporterId}/?token=${token}`;
    console.log(`Attempting to create WebSocket connection with URL: ${websocketURL}`);

    const newSocket = new WebSocket(websocketURL);

    newSocket.onopen = () => {
        console.log('WebSocket connected successfully to room:', masterId, supporterId);
        // ...rest of your logic
    };

    newSocket.onerror = (error) => {
        console.error('WebSocket encountered an error:', error);
        console.log('WebSocket state:', newSocket.readyState);
        console.log('WebSocket URL:', newSocket.url);
    };

    newSocket.onclose = (event) => {
        console.warn('WebSocket connection closed:', event);
        console.warn(`Close event details: wasClean = ${event.wasClean}, code = ${event.code}, reason = ${event.reason}`);
    };

    console.log("WebSocket instance set successfully.");
    setSocket(newSocket);
};


  const sendMessage = () => {
    if (newMessage.trim() === '' || !socket) {
      console.warn("Message is empty or WebSocket is not connected. Message:", newMessage);
      return;
    }

    const messageData = {
      message: newMessage,
      room_id: selectedRoom?.id,
    };

    if (socket.readyState === WebSocket.OPEN) {
      console.log('Sending message through WebSocket:', messageData);
      socket.send(JSON.stringify(messageData));
      setNewMessage('');
    } else {
      console.warn("WebSocket is not open. Message queued for later delivery:", messageData);
      setMessageQueue((prevQueue) => [...prevQueue, messageData]);
    }
  };

  useEffect(() => {

    return () => {
      if (socket) {
        console.log("Closing WebSocket connection on cleanup...");
        socket.close();
      }
    };
  }, [socket]);

  return (
    <section>
      <div className="container py-5">
        <div className="row">
          <ChatRoomList rooms={rooms} handleRoomClick={handleRoomClick} />
          <ChatRoom
            selectedRoom={selectedRoom}
            socket={socket}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            sendMessage={sendMessage}
          />
        </div>
      </div>
    </section>
  );
};

export default ChatComponent;


// // ChatComponent.js
// import React, { useEffect, useState } from 'react';
// import config from '../config';
// import ChatRoomList from './ChatRoomList';
// import ChatRoom from './ChatRoom';

// const ChatComponent = ({ masterId, supporterId }) => {
//   const [rooms, setRooms] = useState([]);
//   const [socket, setSocket] = useState(null);
//   const [selectedRoom, setSelectedRoom] = useState(null);
//   const [newMessage, setNewMessage] = useState('');
//   const [messageQueue, setMessageQueue] = useState([]);

//   useEffect(() => {
//     console.log("Fetching chat rooms...");
//     fetchChatRooms();
//   }, []);

//   const fetchChatRooms = async () => {
//     try {
//       console.log("Making API call to fetch chat rooms...");
//       const response = await fetch(`${config.API_ENDPOINT}/chat/chat-rooms/`, {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.error('Network response was not ok. Status:', response.status, response.statusText);
//         throw new Error('Failed to fetch chat rooms');
//       }

//       const data = await response.json();
//       console.log("Chat rooms fetched successfully:", data);
//       setRooms(data);
//     } catch (error) {
//       console.error('Error fetching chat rooms:', error);
//     }
//   };

//   const handleRoomClick = (room) => {
//     console.log("Clicked room:", room);
//     if (socket) {
//       console.log("Closing existing WebSocket connection...");
//       socket.close(); // Close existing socket
//     }

//     setSelectedRoom(room);
//     console.log(`Selected room set. Creating new WebSocket connection for master ID: ${room.master} and supporter ID: ${room.supporter}`);
//     createWebSocketConnection(room.master, room.supporter);
//   };

//   const createWebSocketConnection = (masterId, supporterId) => {
//     if (!masterId || !supporterId) {
//         console.error("Cannot create WebSocket connection. IDs are undefined:", masterId, supporterId);
//         return;
//     }

//     const token = localStorage.getItem('access_token');
//     const websocketURL = `${config.WEBSOCKET_ENDPOINT}/ws/chat/${masterId}/${supporterId}/?token=${token}`;
//     console.log(`Attempting to create WebSocket connection with URL: ${websocketURL}`);

//     const newSocket = new WebSocket(websocketURL);

//     newSocket.onopen = () => {
//         console.log('WebSocket connected successfully to room:', masterId, supporterId);
//         // ...rest of your logic
//     };

//     newSocket.onerror = (error) => {
//         console.error('WebSocket encountered an error:', error);
//         console.log('WebSocket state:', newSocket.readyState);
//         console.log('WebSocket URL:', newSocket.url);
//     };

//     newSocket.onclose = (event) => {
//         console.warn('WebSocket connection closed:', event);
//         console.warn(`Close event details: wasClean = ${event.wasClean}, code = ${event.code}, reason = ${event.reason}`);
//     };

//     console.log("WebSocket instance set successfully.");
//     setSocket(newSocket);
// };


//   const sendMessage = () => {
//     if (newMessage.trim() === '' || !socket) {
//       console.warn("Message is empty or WebSocket is not connected. Message:", newMessage);
//       return;
//     }

//     const messageData = {
//       message: newMessage,
//       room_id: selectedRoom?.id,
//     };

//     if (socket.readyState === WebSocket.OPEN) {
//       console.log('Sending message through WebSocket:', messageData);
//       socket.send(JSON.stringify(messageData));
//       setNewMessage('');
//     } else {
//       console.warn("WebSocket is not open. Message queued for later delivery:", messageData);
//       setMessageQueue((prevQueue) => [...prevQueue, messageData]);
//     }
//   };

//   useEffect(() => {

//     return () => {
//       if (socket) {
//         console.log("Closing WebSocket connection on cleanup...");
//         socket.close();
//       }
//     };
//   }, [socket]);

//   return (
//     <section>
//       <div className="container py-5">
//         <div className="row">
//           <ChatRoomList rooms={rooms} handleRoomClick={handleRoomClick} />
//           <ChatRoom
//             selectedRoom={selectedRoom}
//             socket={socket}
//             newMessage={newMessage}
//             setNewMessage={setNewMessage}
//             sendMessage={sendMessage}
//           />
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ChatComponent;
