import React, { useCallback } from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBTypography
} from 'mdb-react-ui-kit';
import config from '../config'; // Assuming config file contains GOOGLE_CLIENT_ID and GOOGLE_REDIRECT_URL_ENDPOINT

import applogo from '../imagecollection/applogo.png';
import { Helmet } from 'react-helmet'; // Import Helmet

const Home = () => {
  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    
    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = new URLSearchParams({
      response_type: "code",
      client_id: config.GOOGLE_CLIENT_ID,
      redirect_uri: config.GOOGLE_REDIRECT_URL_ENDPOINT,
      prompt: "select_account",
      access_type: "online",
      scope,
    });

    const url = `${googleAuthUrl}?${params}`;
    window.location.href = url;
  }, []);

  return (
    <>

    <Helmet>
      <title>Discover Your Realm - BDSM Platform</title>
      <meta name="description" content="Discover your realm in BDSM, explore exclusive content, and make meaningful connections with Mistresses and Slaves. Join our platform today to fulfill your deepest desires." />
      <meta name="keywords" content="BDSM, ball busting, CBT, cock and ball torture, Mistress, Slave, BDSM Community, BDSM Platform, Explore Desires, BDSM Fetish, BDSM Kink, Domination, Submission" />
      <meta property="og:title" content="Discover Your Realm - BDSM Platform" />
      <meta property="og:description" content="Explore a world of BDSM, exclusive content, and meaningful connections. Designed for Mistresses and Slaves to satisfy your deepest cravings." />
      <meta property="og:url" content="https://strict-bdsm.com/" />
      <meta property="og:image" content="%PUBLIC_URL%/applogo.png" />
    </Helmet>
    <div style={{
      background: 'linear-gradient(135deg, #c2e9fb, #f6d365)',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px'
    }}>
      <MDBContainer fluid className="p-5" style={{
        maxWidth: '800px',
        backgroundColor: '#ffffff',
        borderRadius: '15px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)'
      }}>
        {/* Title and Context Section */}
        <MDBRow className="text-center mb-5">
          <MDBCol md='6' className="d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <img 
              src={applogo} 
              className="img-fluid"
              alt="Illustration"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </MDBCol>

          <MDBCol md='6' className="d-flex flex-column align-items-center">
            <MDBTypography
              tag="h1"
              className="mb-4 text-uppercase"
              style={{ fontFamily: 'Arial, sans-serif', fontWeight: '700', color: '#333', letterSpacing: '1px' }}
            >
              Discover Your Realm
            </MDBTypography>

            <MDBTypography
              tag="p"
              className="lead"
              style={{
                fontSize: '1.1rem',
                lineHeight: '1.5',
                color: '#666',
                textAlign: 'justify',
                margin: '0 10px',
              }}
            >
              Dive into a world of intrigue and exploration. Explore exclusive content, create meaningful connections, and delve into your deepest desires. Our platform offers a space for both 
              <span style={{ color: '#ff0000', fontWeight: 'bold' }}> Mistress </span> 
              and 
              <span style={{ color: '#0000ff', fontWeight: 'bold' }}> Slave </span>, 
              designed to satisfy your most profound cravings.
            </MDBTypography>

            <MDBBtn
              className="mt-5 w-100"
              size="lg"
              color="success"
              style={{ 
                fontWeight: '700', 
                backgroundColor: '#28a745', 
                borderColor: '#28a745',
                transition: 'all 0.3s',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
              }}
              onClick={openGoogleLoginPage}
            >
              <MDBIcon fab icon="google" className="mx-2" />
              Sign in with Google
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
    </>
  );
}

export default Home;
