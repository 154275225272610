import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';

const TeacherUploadVideoadmin = () => {
    // Form state
    const [videoTitle, setVideoTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false); // New state for loading
    const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
    const [requiresPayment, setRequiresPayment] = useState(false); // New state for payment requirement

    // Create a reference for the form element
    const formRef = useRef(null);

    // Function to get authentication headers
    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    // Convert coordinates to location name using Nominatim API
    const getLocationName = async (latitude, longitude) => {
        const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;
        try {
            const response = await axios.get(url);
            if (response.data && response.data.address) {
                return `${response.data.address.city || ''}, ${response.data.address.state || ''}, ${response.data.address.country || ''}`;
            } else {
                return 'Location not found';
            }
        } catch (error) {
            console.error('Error fetching location:', error);
            return 'Error fetching location';
        }
    };

    // Get the user's current location
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    const locationName = await getLocationName(latitude, longitude);
                    setLocation(locationName);
                },
                (error) => {
                    setLocation('Unable to retrieve location.');
                }
            );
        } else {
            setLocation('Geolocation is not supported by this browser.');
        }
    };

    // Call getCurrentLocation once the component mounts
    useEffect(() => {
        getCurrentLocation();
    }, []);

    // Handle file input (videos only)
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('video/')) {
            setVideoFile(file);
        } else {
            alert('Please select a valid video file.');
        }
    };

    // Handle form submission with API call
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Prepare the form data
        const formData = new FormData();
        formData.append('title', videoTitle);
        formData.append('description', description);
        formData.append('location', location);
        formData.append('is_approved', 'True');
        formData.append('requires_payment', requiresPayment ? 'True' : 'False'); // Convert boolean to string
        formData.append('tags', tags);
        formData.append('file', videoFile);
    
        setIsUploading(true); // Set loading state to true

        try {
            // Send POST request to your API with progress tracking
            const response = await axios.post(`${config.API_ENDPOINT}/video/upload/`, formData, {
                headers: getAuthHeaders(),
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    setUploadProgress(percentCompleted); // Update progress state
                }
            });

     
            setFeedbackMessage('Video uploaded successfully!');
            // Reset form after successful upload
            setVideoTitle('');
            setDescription('');
            setLocation('');
            setTags('');
            setVideoFile(null);
            setRequiresPayment(false); // Reset payment requirement
            setUploadProgress(0); // Reset progress bar
        } catch (error) {
            console.error('Error uploading video:', error);

            // Check for specific errors related to file size or timeouts
            if (error.response && error.response.status === 413) {
                setFeedbackMessage('Failed to upload video. The video size exceeds the 100MB limit.');
            } else if (error.code === 'ECONNABORTED' || error.message.includes('timeout')) {
                setFeedbackMessage('Failed to upload video. The upload time exceeded the limit of 10 minutes.');
            } else {
                setFeedbackMessage('Failed to upload video. Please try again.');
            }
        } finally {
            setIsUploading(false); // Set loading state to false
        }
    };

    const handleCancelClick = (e) => {
        e.preventDefault();
        setVideoTitle('');
        setDescription('');
        setLocation('');
        setTags('');
        setVideoFile(null);
        setRequiresPayment(false); // Reset payment requirement
        setFeedbackMessage('');
        setUploadProgress(0); // Reset progress bar
        setIsUploading(false); // Set loading state to false
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100 bg-light">
            <div className="card shadow-lg p-4" style={{ maxWidth: '600px', width: '100%' }}>
                <h3 className="text-center mb-4">Enter Video Details</h3>

                <form ref={formRef} onSubmit={handleSubmit}>
                    {/* Video Title */}
                    <div className="mb-3">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Video Title"
                            value={videoTitle}
                            onChange={(e) => setVideoTitle(e.target.value)}
                            required
                        />
                    </div>

                    {/* Description */}
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder="Enter Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>

                    {/* Location */}
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Location"
                            className="form-control"
                            value={location}
                            readOnly
                        />
                    </div>

                    {/* Tags */}
                    <div className="mb-3">
                        <input
                            placeholder="Enter Tags"
                            type="text"
                            className="form-control"
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                        />
                    </div>

                    {/* Video File Input (videos only) */}
                    <div className="mb-3">
                        <input
                            type="file"
                            className="form-control"
                            accept="video/*"
                            onChange={handleFileChange}
                            required
                        />
                    </div>

                    {/* Payment Required Checkbox */}
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="paymentRequired"
                                checked={requiresPayment}
                                onChange={(e) => setRequiresPayment(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="paymentRequired">
                                Requires Payment
                            </label>
                        </div>
                    </div>

                    {/* Loading Spinner */}
                    {isUploading && (
                        <div className="mb-3 text-center">
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
                                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Submit and Cancel Buttons */}
                    <div className='d-flex justify-content-between'>
                        <button type="submit" className="btn btn-primary" disabled={isUploading}>Submit</button>
                        <button className='btn btn-secondary' onClick={handleCancelClick} disabled={isUploading}>Cancel</button>
                    </div>

                    {/* Feedback Message */}
                    {feedbackMessage && <div className="mt-3 alert alert-info">{feedbackMessage}</div>}
                </form>
            </div>
        </div>
    );
};

export default TeacherUploadVideoadmin;
