import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import logo from '../imagecollection/applogo.png';


const AddTemplateAdmin = () => {
    // Form state
    const [templateTitle, setTemplateTitle] = useState('');
    const [templateTerms, setTemplateTerms] = useState('');
    const [teacherRole, setTeacherRole] = useState('');
    const [studentRole, setStudentRole] = useState('');
    const [dailyTasks, setDailyTasks] = useState('');
    const [charge, setCharge] = useState('');
    const [previewMode, setPreviewMode] = useState(false);

    // Get authorization headers with token
    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            title: templateTitle,
            template_terms: templateTerms,
            teacher_role: teacherRole,
            student_role: studentRole,
            daily_tasks: dailyTasks,
            charge: charge,
        };


        try {
            const response = await axios.post(
                `${config.API_ENDPOINT}/contracts/contract-templates/`,
                payload,
                {
                    headers: {
                        ...getAuthHeaders(),
                        'Content-Type': 'application/json',
                    }
                }
            );


            alert('Template created successfully!');

        } catch (error) {
            console.error('Error submitting data:', {
                status: error.response ? error.response.status : 'No response',
                data: error.response ? error.response.data : error.message,
                headers: error.response ? error.response.headers : 'No headers'
            });

            if (error.response && error.response.data.detail === "You already have a contract template.") {
                alert('You already have a contract template. Please review or modify your existing template.');
            } else {
                alert('An error occurred while submitting the template.');
            }
        }
    };

    const handleCancelClick = () => {
        setTemplateTitle('');
        setTemplateTerms('');
        setTeacherRole('');
        setStudentRole('');
        setDailyTasks('');
        setCharge('');
        setPreviewMode(false);
    };

    return (
        <div className="container">
            <h3 className="text-center mb-4">Enter Template Details</h3>
            {!previewMode ? (
                <form onSubmit={handleSubmit}>
                    {/* Template Title */}
                    <div className="mb-3">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Template Title"
                            value={templateTitle}
                            onChange={(e) => setTemplateTitle(e.target.value)}
                            required
                        />
                    </div>

                    {/* Template Terms */}
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder="Template Terms"
                            value={templateTerms}
                            onChange={(e) => setTemplateTerms(e.target.value)}
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    {/* Mistress Role */}
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder="Mistress Role"
                            value={teacherRole}
                            onChange={(e) => setTeacherRole(e.target.value)}
                            rows="3"
                        ></textarea>
                    </div>

                    {/* Slave Role */}
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder="Slave Role"
                            value={studentRole}
                            onChange={(e) => setStudentRole(e.target.value)}
                            rows="3"
                        ></textarea>
                    </div>

                    {/* Daily Tasks */}
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            placeholder="Daily Tasks"
                            value={dailyTasks}
                            onChange={(e) => setDailyTasks(e.target.value)}
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    {/* Charge */}
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Charge"
                            className="form-control"
                            value={charge}
                            onChange={(e) => setCharge(e.target.value)}
                            required
                        />
                    </div>

                    {/* Submit and Preview Buttons */}
                    <div className="d-flex justify-content-between">
                        <button type="button" className="btn btn-secondary" onClick={() => setPreviewMode(true)}>Preview</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                        <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                    </div>
                </form>
            ) : (
                <div className="border border-primary rounded p-4 mb-4 bg-light">
                    {/* Logo */}
                    <div className="text-center mb-4">
                        <img src={logo} alt="Logo" className="img-fluid" style={{ maxWidth: '150px' }} />
                    </div>

                    <h4 className="text-center mb-4">Contract Template Preview</h4>

                    <div className="mb-3">
                        <h5>Template Title:</h5>
                        <p>{templateTitle}</p>
                    </div>

                    <div className="mb-3">
                        <h5>Template Terms:</h5>
                        <ul>
                            {templateTerms.split('\n').map((term, index) => (
                                <li key={index}>{term}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="mb-3">
                        <h5>Mistress Role:</h5>
                        <p>{teacherRole}</p>
                    </div>

                    <div className="mb-3">
                        <h5>Slave Role:</h5>
                        <p>{studentRole}</p>
                    </div>

                    <div className="mb-3">
                        <h5>Daily Tasks:</h5>
                        <p>{dailyTasks}</p>
                    </div>

                    <div className="mb-3">
                        <h5>Charge:</h5>
                        <p>{charge}</p>
                    </div>

                    {/* Caption */}
                    <div className="mb-4">
                        <p><strong>Important:</strong> Once you sign the contract, you are required to follow the tasks every day. Failure to comply with the terms may result in consequences as outlined in the template. Ensure that you understand all responsibilities before confirming.</p>
                    </div>

                    {/* Submit and Edit Buttons */}
                    <div className="d-flex justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={() => setPreviewMode(false)}>Edit</button>
                        <button type="button" className="btn btn-success" onClick={handleSubmit}>Confirm & Submit</button>
                        <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddTemplateAdmin;
