import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import './Popup.css'; // Custom CSS for additional styling

const Popup = ({ showPopup, setShowPopup, params }) => {
  // Form state
  const [videoTitle, setVideoTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [tags, setTags] = useState('');
  const [videoFile, setVideoFile] = useState(null);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('access_token');
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  // Create a reference for the form element
  const formRef = useRef(null);

  // Function to handle click outside the form
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowPopup(false); // Close popup when clicked outside
    }
  };

  // Attach the click event listener when popup is shown
  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  // Handle file input (videos only)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
    } else {
      alert('Please select a valid video file.');
    }
  };

  // Handle form submission with API call
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the form data
    const formData = new FormData();
    formData.append('title', videoTitle);
    formData.append('description', description);
    formData.append('location', location);
    formData.append('is_approved', 'True');
    formData.append('requires_payment', 'False');
    formData.append('tags', tags);
    formData.append('file', videoFile);
    formData.append('approver_id', params.id);

    try {
      // Send POST request to your API
      const response = await axios.post(
        `${config.API_ENDPOINT}/video/videos/upload-and-request-approval/`,
        formData,
        {
          headers: getAuthHeaders(),
        }
      );

      // Reset form and close the popup after successful upload
      setVideoTitle('');
      setDescription('');
      setLocation('');
      setTags('');
      setVideoFile(null);
      setShowPopup(false);
      alert(response.data.message);
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };

  const handleCancelClick = () => {
    setVideoTitle('');
    setDescription('');
    setLocation('');
    setTags('');
    setVideoFile(null);
    setShowPopup(false);
  };

  return (
    <div
      className={`popup-overlay ${showPopup ? 'show' : 'hide'} d-flex justify-content-center align-items-center`}
    >
      <form ref={formRef} className="popup-form shadow-lg" onSubmit={handleSubmit}>
        <h3 className="mb-4 text-center">Upload Video</h3>

        {/* Video Title */}
        <div className="mb-3">
          <label htmlFor="videoTitle" className="form-label">Video Title</label>
          <input
            id="videoTitle"
            className="form-control"
            type="text"
            placeholder="Enter Video Title"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            required
          />
        </div>

        {/* Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            id="description"
            className="form-control"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="3"
            required
          ></textarea>
        </div>

        {/* Location */}
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input
            id="location"
            type="text"
            placeholder="Enter Location"
            className="form-control"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        {/* Tags */}
        <div className="mb-3">
          <label htmlFor="tags" className="form-label">Tags</label>
          <input
            id="tags"
            placeholder="Enter Tags (comma separated)"
            type="text"
            className="form-control"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>

        {/* Video File Input */}
        <div className="mb-4">
          <label htmlFor="videoFile" className="form-label">Upload Video</label>
          <input
            id="videoFile"
            type="file"
            className="form-control"
            accept="video/*"
            onChange={handleFileChange}
            required
          />
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary px-4">Submit</button>
          <button type="button" className="btn btn-danger px-4" onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Popup;
