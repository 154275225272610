import React from 'react';

function ContactInformation({ profileInfo }) {
  const handleRedirect = () => {
    window.location.href = '/admin';
  };

  const storedEmail = localStorage.getItem('googleEmail');
  const isEmailMatch = profileInfo?.user.email === storedEmail;

  return (
    <div className="p-4 py-3   mt-5 border rounded bg-light shadow-sm">
      <h3 className="text-secondary mb-3">Contact Information</h3>
      {/* <p><strong>Email:</strong> {profileInfo?.user.email}</p> */}
      
      <p className="mb-3" style={{ fontStyle: 'italic', fontSize: '1.1rem', color: 'black' }}>
        {profileInfo?.profile.bio && profileInfo.profile.bio.length > 100 
          ? `${profileInfo.profile.bio.slice(0, 100)}...` 
          : profileInfo?.profile.bio || 'This user has no bio.'}
      </p>

      {isEmailMatch && (
        <button onClick={handleRedirect} className="btn btn-primary mt-3">
          Go to Admin Page
        </button>
      )}
    </div>
  );
}

export default ContactInformation;
