import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const TeacherUploadVideo = ({ teacherUploadVideo, setTeacherUploadVideo }) => {
    // Form state
    const [videoTitle, setVideoTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [tags, setTags] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
        return token ? { Authorization: `Bearer ${token}` } : {};
    };
    // Create a reference for the form element
    const formRef = useRef(null);

    // Function to handle click outside the form
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setTeacherUploadVideo(false);  // Close popup when clicked outside
        }
    };

    // Attach the click event listener when popup is shown
    useEffect(() => {
        if (teacherUploadVideo) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [teacherUploadVideo]);

    // Handle file input (videos only)
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('video/')) {
            setVideoFile(file);
        } else {
            alert('Please select a valid video file.');
        }
    };

    // Handle form submission with API call
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Prepare the form data
        const formData = new FormData();
        formData.append('title', videoTitle);
        formData.append('description', description);
        formData.append('location', location);
        formData.append('is_approved', 'True');
        formData.append('requires_payment', 'False');
        formData.append('tags', tags);
        formData.append('file', videoFile);
        formData.append('approver_id', '10');

        try {
            // Send POST request to your API
            const response = await axios.post(`${config.API_ENDPOINT}/video/upload/`, formData, {
                headers: getAuthHeaders()
                        });

          
            // Reset form and close the popup after successful upload
            setVideoTitle('');
            setDescription('');
            setLocation('');
            setTags('');
            setVideoFile(null);
            setTeacherUploadVideo(false);

        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    const handleCancelClick = () =>{
        setVideoTitle("");
        setDescription("");
        setLocation("");
        setTags("");
        setVideoFile(null);
        setTeacherUploadVideo(false);
    }

    return (
        <div className={`position-absolute w-100 h-100 bg-dark bg-opacity-25 ${teacherUploadVideo ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={{ zIndex: '10' }}>
            <form ref={formRef} className="p-5 bg-white" onSubmit={handleSubmit}>
                <h3>Enter Video Details</h3>

                {/* Video Title */}
                <input
                    className="form-control mb-2"
                    type="text"
                    placeholder="Video Title"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                    required
                />

                {/* Description */}
                <textarea
                    className="form-control mb-2"
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                ></textarea>

                {/* Location */}
                <input
                    type="text"
                    placeholder="Location"
                    className="form-control mb-2"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />

                {/* Tags */}
                <input
                    placeholder="Enter Tags"
                    type="text"
                    className="form-control mb-2"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                />

                {/* Video File Input (videos only) */}
                <input
                    type="file"
                    className="form-control mb-3"
                    accept="video/*"
                    onChange={handleFileChange}
                    required
                />

                {/* Submit Button */}
                <div className='d-flex justify-content-between'>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button className='btn btn-danger' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default TeacherUploadVideo;
