import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import TemplateEditForm from './TemplateEditForm';
import config from '../config';

const TemplateDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const token = localStorage.getItem('access_token');
    const headers = { Authorization: `Bearer ${token}` };

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await axios.get(`${config.API_ENDPOINT}/contracts/contract-templates/${id}/`, {
                    headers
                });
                setTemplate(response.data);
            } catch (error) {
                console.error('Error fetching template:', error);
            }
        };

        fetchTemplate();
    }, [id, token]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    if (isEditing) {
        return <TemplateEditForm template={template} setIsEditing={setIsEditing} token={token} />;
    }

    return (
        <div className="container template-details-container mt-5">
            <h3 className="text-center mb-4">Template Details</h3>
            {template ? (
                <div className="card shadow-lg p-4">
                    <div className="card-body">
                        <h4 className="card-title mb-4">{template.title}</h4>
                        <div className="mb-3">
                            <p className="mb-1"><strong>Template Terms:</strong></p>
                            <p className="text-muted">{template.template_terms}</p>
                        </div>
                        <div className="mb-3">
                            <p className="mb-1"><strong>Mistress Role:</strong></p>
                            <p className="text-muted">{template.teacher_role}</p>
                        </div>
                        <div className="mb-3">
                            <p className="mb-1"><strong>Slave Role:</strong></p>
                            <p className="text-muted">{template.student_role}</p>
                        </div>
                        <div className="mb-3">
                            <p className="mb-1"><strong>Daily Tasks:</strong></p>
                            <p className="text-muted">{template.daily_tasks}</p>
                        </div>
                        <div className="mb-3">
                            <p className="mb-1"><strong>Charge:</strong></p>
                            <p className="text-muted">${template.charge}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-primary" onClick={handleEditClick}>
                                Edit Template
                            </button>
                            <button className="btn btn-secondary" onClick={handleBackClick}>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-2">Loading template details...</span>
                </div>
            )}
        </div>
    );
};

export default TemplateDetails;
