// src/components/EventList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';

const CreateEvent = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [mode, setMode] = useState('virtual'); // Now a text field
    const [type, setType] = useState('Conference'); // Now a text field
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('access_token');

        const eventData = {
            title,
            description,
            date,
            location,
            mode,
            type,
        };

        try {
            await axios.post(`${config.API_ENDPOINT}/event_host/events/create/`, eventData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            navigate('/EventList'); // Redirect to the event list after creation
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Create Event</h1>
            <form onSubmit={handleSubmit} className="shadow p-4 bg-light rounded">
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Title:</label>
                    <input
                        type="text"
                        id="title"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description:</label>
                    <textarea
                        id="description"
                        className="form-control"
                        rows="4"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">Date:</label>
                    <input
                        type="datetime-local"
                        id="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="location" className="form-label">Location:</label>
                    <input
                        type="text"
                        id="location"
                        className="form-control"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="mode" className="form-label">Mode:</label>
                    <input
                        type="text"
                        id="mode"
                        className="form-control"
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type:</label>
                    <input
                        type="text"
                        id="type"
                        className="form-control"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">Create Event</button>
            </form>
        </div>
    );
};

export default CreateEvent;
