import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/login/googlelogin';
import Main from './components/login/main';

import CancelPage from './components/payment/CancelPage';
import SuccessPage from './components/payment/SuccessPage';
import PayPalButton from './components/payment/PayPalButton';

import ProfileCard from './components/profile/ProfileCard';
import MistressSlaveContractForm from './components/contract/forms';
import Dashbord from './components/dashbord/dashbord';

import Chat from './components/chat/chat';
import ChatComponent from './components/chat/ChatComponent';

import FollowersList from './components/followerslist/FollowersList';
import WebSocketTest from './components/websocket_testing/ChatApp';

import ContactUs from './components/about_us/ContactUs';
import TermsConditions from './components/about_us/TermsConditions';
import ServiceAgreementPopup from './components/profile/ServiceAgreementPopup';
import FollowingList from './components/followerslist/Following';


import AdminPage from './components/profile/AdminPage'; 
 
import PaymentDetailsPage from './components/profile/PaymentDetailsPage';

import VideoApproval from './components/profile/VideoApproval';
import VideoPage  from './components/profile/videopage';
import TeacherUploadVideoadmin  from './components/profile/TeacherUploadVideoadmin';
import AddTemplateAdmin  from './components/profile/AddTemplateAdmin';

import TemplateDetails from './components/profile/TemplateDetails';
import PaymentSuccessPage from './components/payment/PaymentSuccessPage';
import PayPalInfo from './components/profile/paypalinfo';

import UpdateCustomUsername from './components/profile/UpdateCustomUsername';
import EventList from './components/event_host/EventList';
import CreateEvent from './components/event_host/CreateEvent'
import EventDetails from './components/event_host/EventDetails'

function App() {
  const [username, setUsername] = useState(null);
  const [charges, setCharges] = useState("");

  useEffect(() => {
    const storedUsername = localStorage.getItem('googleFirstName');
    setUsername(storedUsername);
  }, []);

  return (
    <Router>
      <Routes>
        {/* If user is logged in, show the main application routes */}
        {username ? (
          <>
            <Route path="/" element={<Dashbord />} />
            <Route path="/main" element={<Main />} />
            <Route path="/profile/:id" element={<ProfileCard />} />

            <Route path="/successPage" element={<SuccessPage />} />
            <Route path="/cancelPage" element={<CancelPage />} />
            <Route path="/PayPalButton" element={<PayPalButton charges={charges}/>} />
            <Route path='/service-agreement/:id' element={<ServiceAgreementPopup setCharges={setCharges}/>}/>
            <Route path="/form" element={<MistressSlaveContractForm />} />
            <Route path="/dashbord" element={<Dashbord />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/ChatComponent" element={<ChatComponent />} />
            <Route path="/FollowersList/:id" element={<FollowersList />} />
            <Route path="/FollowingList/:id" element={<FollowingList/>}/>
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/WebSocketTest" element={<WebSocketTest />} />

            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />

            <Route path="/admin" element={<AdminPage />} />
            <Route path="/video/:id" element={<VideoPage />} /> {/* Route for video page */}
            <Route path="/VideoApproval" element={<VideoApproval />} />

            <Route path="/TeacherUploadVideoadmin" element={<TeacherUploadVideoadmin />} />
            <Route path="/AddTemplateAdmin" element={<AddTemplateAdmin />} />
            <Route path="/contracts/contract-templates/:id" element={<TemplateDetails />} />

            <Route path="/PaymentSuccessPage" element={<PaymentSuccessPage />} />

            <Route path="/PaymentDetailsPage" element={<PaymentDetailsPage />} />
            <Route path="/PayPalInfo" element={<PayPalInfo />} />
            <Route path="/update-username" element={<UpdateCustomUsername />} />
            <Route path="/CreateEvent" element={<CreateEvent />} />
            <Route path="/EventList" element={<EventList />} />
            <Route path="/events/:id" element={<EventDetails />} />
            

 
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </>
        ) : (
          <>
            {/* If user is not logged in, show the login page */}
            <Route path="/login" element={<Home />} />
            {/* Redirect all unknown paths to the login page */}
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/auth" element={<Main />} />

            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
            <Route path="/EventList" element={<EventList />} />
            
            <Route path="/CreateEvent" element={<CreateEvent />} />
            
            <Route path="/events/:id" element={<EventDetails />} />


            
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
