// ChatRoomList.js
import React from 'react';

const ChatRoomList = ({ rooms, handleRoomClick }) => {
  return (
    <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
      <h5 className="font-weight-bold mb-3 text-center text-lg-start">Chat Rooms</h5>
      <div className="card">
        <div className="card-body">
          <ul className="list-unstyled mb-0">
            {rooms.length > 0 ? (
              rooms.map((room, index) => (
                <li key={index} className="p-2 border-bottom" onClick={() => handleRoomClick(room)}>
                  <a href="#!" className="d-flex justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="pt-1">
                        <p className="fw-bold mb-0">
                          {room.other_user_image.custom_username.length > 15
                            ? `${room.other_user_image.custom_username.substring(0, 15)}...`
                            : room.other_user_image.custom_username}
                        </p>
                        <p className="text-muted mb-0">
                          {room.other_user_image.bio
                            ? room.other_user_image.bio.length > 50
                              ? `${room.other_user_image.bio.substring(0, 50)}...`
                              : room.other_user_image.bio
                            : 'Bio not updated'}
                        </p>
                      </div>
                      {room.other_user_image && (
                        <img
                          src={room.other_user_image.custom_profile_image || room.other_user_image.profile_image}
                          alt={room.other_user_image.custom_username}
                          style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px' }}
                        />
                      )}
                    </div>
                  </a>
                </li>
              ))
            ) : (
              <li className="p-2">No chat rooms available.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChatRoomList;
