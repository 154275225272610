import React, { useState } from 'react';

const WebSocketTest = () => {
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState('');
    const [receivedMessages, setReceivedMessages] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');

    const masterId = 5; // Replace with your actual masterId
    const supporterId = 5; // Replace with your actual supporterId

    const connectWebSocket = () => {
        const token = localStorage.getItem('access_token'); // Assuming you have a token saved
        const wsUrl = `ws://localhost:8000/ws/chat/${masterId}/${supporterId}/?token=${token}`;
        console.log('Connecting to:', wsUrl);

        const newSocket = new WebSocket(wsUrl);

        newSocket.onopen = () => {
            console.log('WebSocket connected');
            setConnectionStatus('Connected');
            setSocket(newSocket);
        };

        newSocket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log('Received message:', message);
                setReceivedMessages((prevMessages) => [...prevMessages, message]);
            } catch (error) {
                console.error('Error parsing message:', error);
            }
        };

        newSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setConnectionStatus('Error');
        };

        newSocket.onclose = (event) => {
            console.log('WebSocket closed:', event);
            setConnectionStatus('Disconnected');
            if (event.code === 1006) {
                console.error('Connection closed abnormally. Please check the server.');
            }
        };
    };

    const sendMessage = () => {
        if (socket && socket.readyState === WebSocket.OPEN && message) {
            const messageData = {
                message,
                room_id: `${masterId}_${supporterId}`, // or however your room ID is structured
            };
            socket.send(JSON.stringify(messageData));
            console.log('Sent message:', messageData);
            setMessage(''); // Clear input after sending
        } else {
            console.error('WebSocket is not open or message is empty');
        }
    };

    return (
        <div>
            <h1>WebSocket Test</h1>
            <div>Status: {connectionStatus}</div>
            <button onClick={connectWebSocket} disabled={socket && socket.readyState === WebSocket.OPEN}>
                Connect
            </button>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message"
            />
            <button onClick={sendMessage} disabled={!socket || socket.readyState !== WebSocket.OPEN}>
                Send Message
            </button>
            <div>
                <h2>Received Messages:</h2>
                {receivedMessages.map((msg, index) => (
                    <p key={index}>{JSON.stringify(msg)}</p>
                ))}
            </div>
        </div>
    );
};

export default WebSocketTest;
