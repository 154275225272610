import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import config from '../config';
const FollowersList = () => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataEmpty, setDataEmpty] = useState(false);
  const [visibleCount, setVisibleCount] = useState(0); // Initialize based on screen width
  const [loadAmount, setLoadAmount] = useState(0); // For load more button functionality
  const params = useParams();
  const navigate = useNavigate();

  // Function to determine screen width and set initial load count
  const determineLoadAmount = () => {
    if (window.innerWidth >= 1024) {
      setVisibleCount(70); // Load 70 profiles for desktop
      setLoadAmount(70); // No more load on desktop
    } else {
      setVisibleCount(10); // Load 10 profiles for mobile
      setLoadAmount(10); // Load more in increments of 10 on mobile
    }
  };
  const getAuthHeaders = () => {
    const token = localStorage.getItem('access_token');
      return token ? { Authorization: `Bearer ${token}` } : {};
};

  // Fetch followers on component mount
  useEffect(() => {
    setLoading(true)
    axios.get(`${config.API_ENDPOINT}/follow/followers/${params.id}/`, { headers: getAuthHeaders() })
      .then(response => {
        const data = response.data;

        
        setDataEmpty(data.length === 0);
        setFollowers(data);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Set load amount based on screen size
    determineLoadAmount();

    // Add event listener for window resize to adjust loadAmount dynamically
    window.addEventListener('resize', determineLoadAmount);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', determineLoadAmount);
    };
  }, []);

  // Handle load more profiles
  const handleLoadMore = () => {
    setVisibleCount((prev) => prev + loadAmount); // Load profiles in the same amount on mobile
  };

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };

  return (
    <div>
        {dataEmpty && "No Follower"}
        {loading && <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
        <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>}
      <Grid container spacing={2}>
        {followers.slice(0, visibleCount).map((follower) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={follower.id}>
            <List>
              <ListItem alignItems="flex-start" button onClick={() => handleProfileClick(follower.id)}>
                <ListItemAvatar>
                  <Avatar alt={follower.custom_username} src={follower.custom_profile_image} />
                </ListItemAvatar>
                <ListItemText
                  primary={follower.custom_username}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: 'text.primary', display: 'inline' }}
                      >
                        {follower.custom_username}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          </Grid>
        ))}
      </Grid>
      {visibleCount < followers.length && (
        <Button onClick={handleLoadMore} variant="contained" color="primary" sx={{ marginTop: 2 }}>
          Load More
        </Button>
      )}
    </div>
  );
};

export default FollowersList;
