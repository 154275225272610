import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './PaymentSuccessPage.css';  // Import the CSS file

const PaymentSuccessPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const goToDashboard = () => {
        navigate('/dashboard');  // Redirect to dashboard
    };

    return (
        <div className="payment-success-page">
            <div className="success-icon">
                <i className="fas fa-check-circle"></i>
            </div>
            <h1>Payment Successful!</h1>
            <p>Thank you for your payment. Your transaction was completed successfully.</p>
            <button className="dashboard-button" onClick={goToDashboard}>Go to Dashboard</button>
        </div>
    );
};

export default PaymentSuccessPage;
