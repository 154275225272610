import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import axios from 'axios';
import config from '../config';
import ProfileHeader from './ProfileHeader';
import ServiceAgreement from './ServiceAgreement';
import ContactInformation from './ContactInformation';
import VideoSection from './VideoSection';
import Popup from './Popup';
import ServiceAgreementPopup from './ServiceAgreementPopup';
import AddTemplate from './AddTemplate';
import TeacherUploadVideo from './TeacherUploadVideo';

function ProfileCard() {
  const [teacherUploadVideo, setTeacherUploadVideo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [displayAggreement, setDisplayAggreement] = useState(false);
  const [displayAddTemplate, setDisplayAddTemplate] = useState(false);
  const [teacherVideos, setTeacherVideos] = useState([]);
  const [studentVideos, setStudentVideos] = useState([]);
  const [paidVideos, setPaidVideos] = useState([]);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);

  const [userId, setUserId] = useState(null); // To store fetched user_id
  const [canChat, setCanChat] = useState(false);
  const params = useParams();
  const profileId = params.id

  // Function to handle navigation to FollowersList page
  const handleFollowersClick = () => {
    navigate('/followers');
  };


  // First API call to get user_idadmin
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem('access_token');

        // First API call to get user_id (replace with your actual API)
        const response = await axios.get(`${config.API_ENDPOINT}/get-user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedUserId = response.data.user_id; // Assuming this is the structure
        setUserId(fetchedUserId); // Set the user_id (e.g., 9)
      } catch (error) {
        console.error('Failed to fetch user_id:', error);
      }
    };

    fetchUserId();
  }, []);

  // Second API call to get can_chat using the fetched user_id
  useEffect(() => {
    if (userId) {
      const fetchCanChat = async () => {
        try {
          const token = localStorage.getItem('access_token');

          // Use the userId in the second API call
          const response = await axios.get(`${config.API_ENDPOINT}/follow/can-chat/${params.id}/${userId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setCanChat(response.data.can_chat); // Set can_chat value
        } catch (error) {
          console.error('Failed to fetch can_chat status:', error);
        }
      };

      fetchCanChat();
    }
  }, [userId, params.id]); // Make sure to trigger this when userId is set
  useEffect(() => {
    
    const fetchTeacherVideos = async () => {
      try {
        const token = localStorage.getItem('access_token');

        const response = await axios.get(`${config.API_ENDPOINT}/video/videos/my-uploads/${params.id}/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response, "dasd");
        
        setTeacherVideos(response.data);
      } catch (error) {
        console.error('Failed to fetch teacher videos:', error);
      }
    };

    fetchTeacherVideos();
  }, []);
 const handleFetchUserVideos = async (videoId) =>{
  try {
    const token = localStorage.getItem('access_token');
    const response = await axios.get(`${config.API_ENDPOINT}/video/video/${videoId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // console.log(response.data);
    
    setStudentVideos((prevVideos) => [...prevVideos, response.data]); 
    // setStudentVideos(response.data);
    // console.log("Student", response.data)
  } catch (error) {
    console.error('Failed to fetch student videos:', error);
  }
 }

  useEffect(() => {
    const fetchStudentVideos = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${config.API_ENDPOINT}/video/videos/approved/requester-approver/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // setStudentVideos(response.data.approved_videos);
        // console.log("Student", response.data)
        const approvedVideos = response.data.approved_videos;
      console.log("Approved Videos", approvedVideos);

      // Fetch details for each video using its ID
      approvedVideos.forEach(async (video) => {
        await handleFetchUserVideos(video.video);  // Pass video ID to handleFetchUserVideos
      });

      } catch (error) {
        console.error('Failed to fetch student videos:', error);
      }
    };

    fetchStudentVideos();
  }, []);


  useEffect(() => {
    const fetchPaidVideos = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${config.API_ENDPOINT}/video/videos/paid/${params.id}/`, { // Replace with actual endpoint if necessary
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPaidVideos(response.data);
      } catch (error) {
        console.error('Failed to fetch paid videos:', error);
      }
    };

    fetchPaidVideos();
  }, []);
  

  // Function to handle delete request
  const handleDeleteVideo = async (videoId) => {
    try {
      const token = localStorage.getItem('access_token');
      await axios.delete(`${config.API_ENDPOINT}/video/video/delete/${videoId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

  
      setTeacherVideos(teacherVideos.filter(video => video.id !== videoId));
      alert('Video deleted successfully');
    } catch (error) {
      console.error('Failed to delete video:', error);
      alert('Error deleting video');
    }
  };
  useEffect(()=> {
    const token = localStorage.getItem('access_token');
  const fetchingProfileData = async ()=>{ 
    const response = await axios.get(`${config.API_ENDPOINT}/UserEngagement/${params.id}`,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    try {
      console.log(response);
      setProfileData(response.data)
    } catch (error) {
      console.log("Getting Error in profile data", error);
    
    }
  }
  fetchingProfileData();
  const fetchingProfileInfo = async ()=>{ 
    const response = await axios.get(`${config.API_ENDPOINT}/api/api/user/details/${params.id}/`,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    try {
      console.log(response);
      setProfileInfo(response.data)
    } catch (error) {
      console.log("Getting Error in profile data", error);
    
    }
  }
  fetchingProfileInfo();
  
  },[])
// console.log(teacherVideos);



const openPopup = () => {
  setShowPopup(true);
  window.scrollTo(0, 0); // Scroll to top
};

  return (
<section className="h-100 gradient-custom-2" style={{ position: 'relative' }}>
  <Popup showPopup={showPopup} params={params} setShowPopup={setShowPopup} />
  {/* <ServiceAgreementPopup setDisplayAggreement={setDisplayAggreement} displayAggreement={displayAggreement}/> */}
  <AddTemplate displayAddTemplate={displayAddTemplate} setDisplayAddTemplate={setDisplayAddTemplate} />
  <TeacherUploadVideo teacherUploadVideo={teacherUploadVideo} setTeacherUploadVideo={setTeacherUploadVideo} />

  <div className="container py-5">
    <ProfileHeader
      profileInfo={profileInfo}
      profileData={profileData}
      setDisplayAddTemplate={setDisplayAddTemplate}
      user={{ name: "Andy Horwitz", location: "New York" }}
      onEditProfileClick={handleFollowersClick}


    />

<div className="row mt-4">
  <div className="col-12">
    <div className="bg-light rounded shadow-sm p-4">
      <div className="row text-center text-md-start py-3">
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <div className="bg-primary text-white p-3 rounded">
            <p className="mb-1 h4">{teacherVideos.length}</p>
            <p className="small mb-0">Videos</p>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <div className="bg-success text-white p-3 rounded">
            <p className="mb-1 h4">{profileData?.follower_count}</p>
            <p className="small mb-0">Followers</p>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="bg-info text-white p-3 rounded">
            <p className="mb-1 h4">{profileData?.following_count}</p>
            <p className="small mb-0">Following</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    <div className="row">
      <div className="col-12 col-md-6 mb-4 mb-md-0">
        <ServiceAgreement userId={params.id} />
      </div>
      <div className="col-12 col-md-6 mb-4 mb-md-0">
        <ContactInformation profileInfo={profileInfo} />
      </div>
    </div>

    <div className="row">

    <div className="row mt-4">

      
      <div className="col-12">
        <VideoSection
          setShowPopup={openPopup}
          title="Slave Videos"
          videos={studentVideos}
          profileId={profileId}
          userId={userId}
        />
      </div>


      <div className="col-12 mt-4">
        <VideoSection
          setTeacherUploadVideo={setTeacherUploadVideo}
          title="Mistress Videos"
          videos={teacherVideos}
          onDelete={handleDeleteVideo} 
          profileId={profileId}
          userId={userId}
        />
      </div>
      </div>

      <div className="row"></div>

      {(canChat || parseInt(userId, 10) === parseInt(profileId, 10)) && (
        <div className="col-12 mt-4">
          <VideoSection
            title="Paid Videos"
            videos={paidVideos}
            onDelete={handleDeleteVideo}
            profileId={profileId}
            userId={userId}
          />
        </div>
      )}
    </div>
  </div>
</section>

  );
}

export default ProfileCard;