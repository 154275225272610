import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const AddTemplate = ({ displayAddTemplate, setDisplayAddTemplate }) => {
    // Form state
    const [templateTitle, setTemplateTitle] = useState('');
    const [templateTerms, setTemplateTerms] = useState('');
    const [teacherRole, setTeacherRole] = useState('');
    const [studentRole, setStudentRole] = useState('');
    const [dailyTasks, setDailyTasks] = useState('');
    const [charge, setCharge] = useState('');

    // Create a reference for the form element
    const formRef = useRef(null);
    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
            return token ? { Authorization: `Bearer ${token}` } : {};
      };
    // Function to handle click outside the form
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setDisplayAddTemplate(false);  // Close popup when clicked outside
        }
    };

    // Attach the click event listener when popup is shown
    useEffect(() => {
        if (displayAddTemplate) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [displayAddTemplate]);

    // Handle form submission (for now just log the data)
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Construct the payload
        const payload = {
            title: templateTitle,
            template_terms: templateTerms,
            teacher_role: teacherRole,
            student_role: studentRole,
            daily_tasks: dailyTasks,
            charge: charge,
        };

        try {
            // Make the POST request using axios
            const response = await axios.post(
                `${config.API_ENDPOINT}/contracts/contract-templates/`,
                payload,
                {
                    headers: getAuthHeaders(),
                }
            );



        } catch (error) {
            console.error('Error submitting data:', error);
            // Handle the error
        }
    };

    const handleCancelClick = () => {
        setTemplateTitle('');
        setTemplateTerms('');
        setTeacherRole('');
        setStudentRole('');
        setDailyTasks('');
        setCharge('');
        setDisplayAddTemplate(false);
    };

    return (
        <div className={`position-absolute w-100 h-100 bg-dark bg-opacity-25 ${displayAddTemplate ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={{ zIndex: '10' }}>
            <form ref={formRef} className="p-5 bg-white" style={{ marginTop: "-120px" }} onSubmit={handleSubmit}>
                <h3>Enter Template Details</h3>

                {/* Template Title */}
                <input
                    className="form-control mb-2"
                    type="text"
                    placeholder="Template Title"
                    value={templateTitle}
                    onChange={(e) => setTemplateTitle(e.target.value)}
                    required
                />

                {/* Template Terms */}
                <textarea
                    className="form-control mb-2"
                    placeholder="Template Terms"
                    value={templateTerms}
                    onChange={(e) => setTemplateTerms(e.target.value)}
                    required
                ></textarea>

                {/* Mistress Role */}
                <input
                    type="text"
                    placeholder="Mistress Role"
                    className="form-control mb-2"
                    value={teacherRole}
                    onChange={(e) => setTeacherRole(e.target.value)}
                />

                {/* Slave Role */}
                <input
                    type="text"
                    placeholder="Slave Role"
                    className="form-control mb-2"
                    value={studentRole}
                    onChange={(e) => setStudentRole(e.target.value)}
                />

                {/* Daily Tasks */}
                <input
                    type="text"
                    placeholder="Daily Tasks"
                    className="form-control mb-3"
                    value={dailyTasks}
                    onChange={(e) => setDailyTasks(e.target.value)}
                    required
                />

                {/* Charge */}
                <input
                    type="text"
                    placeholder="Charge"
                    className="form-control mb-3"
                    value={charge}
                    onChange={(e) => setCharge(e.target.value)}
                    required
                />

                {/* Submit and Cancel Buttons */}
                <div className='d-flex justify-content-between'>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-danger" onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default AddTemplate;
