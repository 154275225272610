import React, { useState } from 'react';
import ContractPreview from './ContractPreview'; // Import the new component

function TeacherStudentContractForm() {
    const [formData, setFormData] = useState({
        teacherName: 'Mistress Name Placeholder',
        aboutTeacher: 'Brief Description About the Mistress',
        studentName: '',
        requirement: 'The Slave must provide a dog as required by the Mistress.',
        tasks: 'The Slave is required to serve the Mistress in all tasks and duties as outlined by the Mistress.',
        timePeriod: '6 months',
        contractFee: '$1000',
    });

    const [showPreview, setShowPreview] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePreview = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };

    const handleConfirm = () => {
    
        alert("Contract has been created. Check the console for details.");
    };

    return (
        <div className="container mt-5">
            <div className="card shadow-lg">
                <div className="card-header bg-primary text-white">
                    <h2 className="text-center">Mistress-Slave Contract Form</h2>
                </div>
                <div className="card-body">
                    {!showPreview ? (
                        <form onSubmit={handlePreview}>
                            <div className="form-group">
                                <label>Mistress Name:</label>
                                <input
                                    type="text"
                                    name="teacherName"
                                    className="form-control"
                                    value={formData.teacherName}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>About Mistress:</label>
                                <textarea
                                    name="aboutTeacher"
                                    className="form-control"
                                    value={formData.aboutTeacher}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Slave Name:</label>
                                <input
                                    type="text"
                                    name="studentName"
                                    className="form-control"
                                    value={formData.studentName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Requirement:</label>
                                <textarea
                                    name="requirement"
                                    className="form-control"
                                    value={formData.requirement}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Tasks:</label>
                                <textarea
                                    name="tasks"
                                    className="form-control"
                                    value={formData.tasks}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Time Period:</label>
                                <input
                                    type="text"
                                    name="timePeriod"
                                    className="form-control"
                                    value={formData.timePeriod}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Contract Fee:</label>
                                <input
                                    type="text"
                                    name="contractFee"
                                    className="form-control"
                                    value={formData.contractFee}
                                    readOnly
                                />
                            </div>
                            <button type="button" className="btn btn-secondary btn-block mt-4" onClick={() => setShowPreview(false)}>
                                Edit
                            </button>
                            <button type="submit" className="btn btn-primary btn-block mt-4">
                                Preview Contract
                            </button>
                        </form>
                    ) : (
                        <ContractPreview
                            formData={formData}
                            onConfirm={handleConfirm}
                            onEdit={() => setShowPreview(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default TeacherStudentContractForm;
