import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoutButton from './LogoutButton'; // Import the LogoutButton component
import config from "../config";

import { Helmet } from 'react-helmet';


const Home = () => {
  const [username, setUsername] = useState(localStorage.getItem('googleFirstName'));
  const [loading, setLoading] = useState(true); // New state for loading
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchUserData = async (accessToken) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/get-user/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      localStorage.setItem('user_id_data', data.user_id); // Save user_id to local storage
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get('access_token');
    const refreshToken = query.get('refresh_token');
    const firstName = query.get('first_name');
    const email = query.get('email');

    if (accessToken && refreshToken && firstName && email) {
      // Save tokens and user info in local storage or cookies
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      localStorage.setItem('googleFirstName', firstName);
      localStorage.setItem('googleEmail', email);

      fetchUserData(accessToken);
      

      // Update state to show the user's name
      setUsername(firstName);

      // Wait for data to be set before redirecting
      setTimeout(() => {
        setLoading(false); // Set loading to false before redirect
        navigate('/dashbord'); // Use navigate for redirection
      }, 1000); // Optional delay to ensure local storage update
    } else {
      setLoading(false); // In case there are no tokens, stop loading
    }
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f0f0' }}>
        <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      </div>
    );
  }

  return (
      <>
      <Helmet>
       <title>strict-bdsm</title>
        <meta name="description" content="Discover your realm in BDSM, explore exclusive content, and make meaningful connections with Mistresses and Slaves. Join our platform today to fulfill your deepest desires." />
        <meta name="keywords" content="BDSM, ball busting, CBT, cock and ball torture, Mistress, Slave, BDSM Community, BDSM Platform, Explore Desires, BDSM Fetish, BDSM Kink, Domination, Submission" />
        <meta property="og:title" content="Discover Your Realm - BDSM Platform" />
        <meta property="og:description" content="Explore a world of BDSM, exclusive content, and meaningful connections. Designed for Mistresses and Slaves to satisfy your deepest cravings." />
        <meta property="og:url" content="https://strict-bdsm.com/" />
        <meta property="og:image" content="%PUBLIC_URL%/applogo.png" />
      </Helmet>
    <div className="flex flex-col items-center justify-center h-screen">

      
      <h1>Welcome, {username}</h1>
      <LogoutButton /> {/* Use the LogoutButton component */}
    </div>
    </>
  );
};

export default Home;
