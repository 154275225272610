import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import loading_image from '../imagecollection/giphy.webp';

const FollowersPopup = ({selectedList, setDisplayFollowersPopup, displayFollowersPopup, selectedUserName, userFollowers }) => {
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [following, setFollowing] = useState([]);
    const [supporters, setSupporters] = useState(false);

    const getAuthHeaders = () => {
        const token = localStorage.getItem('access_token');
           return token ? { Authorization: `Bearer ${token}` } : {};
      };

    const formRef = useRef(null);

    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setDisplayFollowersPopup(false);
        }
    };

    useEffect(() => {
        if (displayFollowersPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [displayFollowersPopup]);

    useEffect(() => {
        if (userFollowers) {
            setLoading(true);
            const endpoint = selectedList === "Followers" ? 'followers' : selectedList === "Following" ? 'following' : 'followers';
            axios.get(`${config.API_ENDPOINT}/follow/${endpoint}/${userFollowers}/`, { headers: getAuthHeaders() })
                .then(response => {
                    const data = response.data;
                    setDataEmpty(data.length === 0);
                    if (selectedList === "Followers") setFollowers(data);
                    if (selectedList === "Following") setFollowing(data);
                    if (selectedList === "Support") setSupporters(data);
                })
                .catch(error => {
                    console.error("There was an error fetching the data!", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        if(userFollowers && selectedList === "Supports"){
            setLoading(true);
            // const endpoint = selectedList === "Followers" ? 'followers' : selectedList === "Following" ? 'following' : 'followers';
            axios.get(`${config.API_ENDPOINT}/follow/can-chat/${userFollowers}/1`, { headers: getAuthHeaders() })
                .then(response => {
                    const data = response.data;
                    setDataEmpty(data.length === 0);
              
                                                    
                    if (selectedList === "Support") setSupporters(data.can_chat);
                })
                .catch(error => {
                    console.error("There was an error fetching Support data!", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [userFollowers, selectedList]);

    return (
        <div className={`position-absolute w-100 h-100 bg-dark bg-opacity-25 ${displayFollowersPopup ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={{ zIndex: '100' }}>
            <div ref={formRef} className="p-5 bg-white">
                <h3>User Name: {selectedUserName}</h3>

                {/* Show loading state while fetching */}
                {loading ? (
                      <img src={loading_image} alt="Loading" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
                ) : (
                    <>
                        {selectedList === "Followers" && dataEmpty && <h3>No followers</h3>}
                        {selectedList === "Followers" && followers?.map((follower, index) => (
                            <div key={index}>
                                <li>{follower.first_name}</li>
                            </div>
                        ))}

                        {selectedList === "Following" && dataEmpty && <h3>Not following anyone</h3>}
                        {selectedList === "Following" && following?.map((follower, index) => (
                            <div key={index}>
                                <li>{follower.first_name}</li>
                            </div>
                        ))}

                        {selectedList === "Supports"  && !supporters && <h3>You Cannot Chat {selectedUserName}</h3>}
                        {selectedList === "Supports" && supporters && <h3>You Can Chat {selectedUserName}</h3> }
                    </>
                )}
            </div>
        </div>
    );
};

export default FollowersPopup;
