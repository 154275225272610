import React, { useEffect, useState } from 'react';
import config from '../config';

const ChatComponent = ({ masterId, supporterId }) => {
  const [rooms, setRooms] = useState([]);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [messageQueue, setMessageQueue] = useState([]); // Queue for messages to send

  useEffect(() => {
    fetchChatRooms(); // Fetch chat rooms when component mounts
  }, []);

  const fetchChatRooms = async () => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/chat/chat-rooms/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Chat Rooms Response:', data);
      setRooms(data); // Update state with fetched rooms
    } catch (error) {
      console.error('Error fetching chat rooms:', error);
    }
  };

  const fetchOldMessages = async (room) => {
    try {
      const response = await fetch(`${config.API_ENDPOINT}/chat/chat/messages/${room.id}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Old Messages Response:', data);
      setMessages(data); // Set old messages in state

      // Create WebSocket connection using room's master and supporter IDs
      createWebSocketConnection(room.master, room.supporter);
    } catch (error) {
      console.error('Error fetching old messages:', error);
    }
  };

  const handleRoomClick = (room) => {
    console.log('Room clicked:', room);
    setSelectedRoom(room);
    fetchOldMessages(room);
  };

  const createWebSocketConnection = (masterId, supporterId) => {
    if (!masterId || !supporterId) {
      console.error("Cannot create WebSocket connection. IDs are undefined:", masterId, supporterId);
      return;
    }

    const newSocket = new WebSocket(`${config.WEBSOCKET_ENDPOINT}/ws/chat/${masterId}/${supporterId}/?token=${localStorage.getItem('access_token')}`);

    newSocket.onopen = () => {
      console.log('WebSocket connected to room:', masterId, supporterId);
      // Send any queued messages
      messageQueue.forEach((message) => {
        newSocket.send(JSON.stringify(message));
      });
      setMessageQueue([]); // Clear the queue after sending
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Message received:', data);
      setMessages((prevMessages) => [...prevMessages, data]); // Update messages with new data
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket disconnected:', event);
    };

    setSocket(newSocket);
  };

  const sendMessage = () => {
    if (newMessage.trim() === '' || !socket) {
      return; // Prevent sending empty messages or if socket is not connected
    }

    const messageData = {
      message: newMessage,
      room_id: selectedRoom.id, // Include the room ID if necessary
    };

    if (socket.readyState === WebSocket.OPEN) {
      // Send the message through the WebSocket
      socket.send(JSON.stringify(messageData));
      setNewMessage(''); // Clear the input field
    } else {
      // If WebSocket is not open, add message to the queue
      setMessageQueue((prevQueue) => [...prevQueue, messageData]);
      console.error("WebSocket is not open. Message queued.");
    }
  };

  // Cleanup WebSocket connection when component unmounts or room changes
  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return (
    <section>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
            <h5 className="font-weight-bold mb-3 text-center text-lg-start">Chat Rooms</h5>
            <div className="card">
              <div className="card-body">
                <ul className="list-unstyled mb-0">
                  {rooms.length > 0 ? (
                    rooms.map((room, index) => (
                      <li key={index} className="p-2 border-bottom" onClick={() => handleRoomClick(room)}>
                        <a href="#!" className="d-flex justify-content-between">
                          <div className="d-flex flex-row">
                            <div className="pt-1">
                              <p className="fw-bold mb-0">Room ID: {room.id}</p>
                              <p className="text-muted mb-0">Master: {room.master}, Supporter: {room.supporter}</p>
                            </div>
                            {room.other_user_image && (
                              <img 
                                src={room.other_user_image.custom_profile_image || room.other_user_image.profile_image} 
                                alt={room.other_user_image.custom_username} 
                                style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px' }} 
                              />
                            )}
                          </div>
                        </a>
                      </li>
                    ))
                  ) : (
                    <li className="p-2">No chat rooms available.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-7 col-xl-8">
            {selectedRoom && (
              <ul className="list-unstyled">
                <h3>Messages in Room ID: {selectedRoom.id}</h3>
                {messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <li key={index} className="d-flex justify-content-between mb-4">
                      <strong>{msg.sender_username}:</strong> <span>{msg.message}</span>
                    </li>
                  ))
                ) : (
                  <li>No messages available.</li>
                )}
                <li className="bg-white mb-3">
                  <div data-mdb-input-init className="form-outline">
                    <textarea 
                      className="form-control bg-body-tertiary" 
                      id="textAreaExample2" 
                      rows="4" 
                      value={newMessage} 
                      onChange={(e) => setNewMessage(e.target.value)} 
                      placeholder="Type your message..." 
                    />
                    <label className="form-label" htmlFor="textAreaExample2">Message</label>
                  </div>
                </li>
                <button 
                  type="button" 
                  className="btn btn-info btn-rounded float-end" 
                  onClick={sendMessage}
                >
                  Send
                </button>
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatComponent;