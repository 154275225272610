import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
const VideoCard = ({ 
  video, 
  isPlaying, 
  onPlay, 
  onLike, 
  onCommentInputChange, 
  onCommentSubmit, 
  commentInputValue,
  editCommentId,
  editCommentContent,
  setEditCommentId,
  setEditCommentContent,
  editCommentOnVideo,
  userId,
  replyComment,
  handleDeleteComment,
  parentReplyId,
  setParentReplyId,
  setReplyComment,
  handleCommentReply
}) => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  const handlePlay = () => {
    onPlay(video.id);
  };

  const handleEditClick = (comment) => {
    setEditCommentId(comment.id);
    setEditCommentContent(comment.content);
  };

  const handleEditSave = (commentId) => {
    editCommentOnVideo(commentId, editCommentContent);
  };

  const handleReplyChange = (e) => {
    setReplyComment(e.target.value);
  };

  const handleReplySubmit = (commentId) => {
    handleCommentReply(commentId);
    setReplyComment(""); // Clear the reply input after submission
    setParentReplyId(null); // Reset parent reply ID
  };

  const renderComments = (comments) => {
    if (!Array.isArray(comments) || comments.length === 0) {
      return <p>No comments available</p>;
    }

    const renderCommentTree = (comment) => {
      return (
        <div key={comment.id} style={{ marginBottom: '10px' }}>
          {editCommentId === comment.id ? (
            <div>
              <input
                value={editCommentContent}
                onChange={(e) => setEditCommentContent(e.target.value)}
                style={{ border: '1px solid #ddd', padding: '5px' }}
              />
              <button onClick={() => handleEditSave(comment.id)}>Save</button>
              <button onClick={() => setEditCommentId(null)}>Cancel</button>
            </div>
          ) : (
            <p>
              <strong>{comment.user}</strong>: {comment.content}{" "}
              {comment.user === userId && (
                <>
                  <span
                    style={{ fontSize: '12px', cursor: 'pointer' }}
                    onClick={() => handleEditClick(comment)}
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => handleDeleteComment(comment.id)}
                    style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '12px', color: 'red' }}
                  >
                    Delete
                  </span>
                </>
              )}
                                <span
                    onClick={() => setParentReplyId(comment.id)}
                    style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '12px', color: 'green' }}
                  >
                    Reply
                  </span>

            </p>
          )}

          {/* Render reply input if this comment is the target for replying */}
          {parentReplyId === comment.id && (
            <div style={{ marginTop: '10px' }}>
              <input
                type="text"
                value={replyComment}
                onChange={handleReplyChange}
                placeholder="Write a reply..."
                style={{ border: '1px solid #ddd', padding: '5px' }}
              />
              <button onClick={() => handleReplySubmit(comment.id)} style={{ marginLeft: '10px' }}>Submit</button>
            </div>
          )}

          {/* Render replies, if any */}
          {comment.replies && comment.replies.length > 0 && (
            <div style={{ marginLeft: '20px' }}>
              {comment.replies.map(reply => renderCommentTree(reply))}
            </div>
          )}
        </div>
      );
    };

    return comments.map(comment => renderCommentTree(comment));
  };
  return (
    <div className="">
      <div className="card" style={{ borderRadius: '10px', overflow: 'hidden' }}>
        {/* Video Player */}
        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
          <video
            ref={videoRef}
            src={video.file}
            title={video.title}
            controls
            onPlay={handlePlay}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '10px'
            }}
          >
            Your browser does not support the video tag.
          </video>
        </div>
        {/* Card Body */}
        <div className="card-body">
          {/* Video Title */}
          <h5 className="card-title" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
            {video.title}
          </h5>
          {/* Video Description */}
          <p className="card-text text-muted" style={{ fontSize: '0.9rem' }}>
            {video.description}
          </p>

          {/* Uploader Info */}
          <div className="d-flex align-items-center" style={{ marginBottom: '10px' }}>
            <img
              onClick={()=>navigate(`/profile/${video.uploader_id}`)}
              src={video.uploader_profile_picture}
              alt="Uploader Profile"
              style={{cursor:"pointer", width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
            />
            <p className="card-text">
              Uploaded by:
              <Link to={`/profile/${video.uploader_id}`} style={{ marginLeft: '5px', color: '#007bff', textDecoration: 'none' }}>
                {video.custom_username}
              </Link>
            </p>
          </div>

          {/* Video Metadata */}
          <div className="d-flex justify-content-between text-muted" style={{ fontSize: '0.8rem' }}>
            <span>{video.total_views} Views</span>
            <span onClick={() => onLike(video.id)} style={{ cursor: 'pointer' }}>{video.total_likes} Likes</span>
          </div>

          {/* Comments */}
          <div style={{ marginTop: '20px' }}>
            <input
              type="text"
              placeholder="Comment"
              value={commentInputValue}
              onChange={onCommentInputChange} 
              style={{ border: 'none',outline: 'none' ,borderBottom: '1px solid #999' }}
            />
            <button style={{marginLeft:'10px',border: 'none'}} onClick={onCommentSubmit}>Submit</button>
            {renderComments(video.comments)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
