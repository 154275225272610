import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

const TemplateEditForm = ({ template, setIsEditing, token }) => {
    // Log the template object for debugging


    const [formData, setFormData] = useState({
        title: template?.title || '',
        template_terms: template?.template_terms || '',
        teacher_role: template?.teacher_role || '',
        student_role: template?.student_role || '',
        daily_tasks: template?.daily_tasks || '',
        charge: template?.charge || '',
        teacher: template.teacher || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

   
        try {
            const response = await axios.put(
                `${config.API_ENDPOINT}/contracts/contract-templates/${template?.id}/update/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
   
            alert('Template updated successfully!');
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating template:', error.response ? error.response.data : error.message);
            alert('Failed to update template.');
        }
    };

    return (
        <div className="container mt-5">
            <div className="card shadow-lg p-4">
                <h3 className="card-title text-center mb-4">Edit Template</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Enter template title"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Template Terms</label>
                        <textarea
                            className="form-control"
                            name="template_terms"
                            value={formData.template_terms}
                            onChange={handleChange}
                            rows="4"
                            placeholder="Describe the terms of the template"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Mistress Role</label>
                        <textarea
                            className="form-control"
                            name="teacher_role"
                            value={formData.teacher_role}
                            onChange={handleChange}
                            rows="3"
                            placeholder="Describe the teacher's role"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Slave Role</label>
                        <textarea
                            className="form-control"
                            name="student_role"
                            value={formData.student_role}
                            onChange={handleChange}
                            rows="3"
                            placeholder="Describe the student's role"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Daily Tasks</label>
                        <textarea
                            className="form-control"
                            name="daily_tasks"
                            value={formData.daily_tasks}
                            onChange={handleChange}
                            rows="4"
                            placeholder="List the daily tasks"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Charge</label>
                        <input
                            type="text"
                            className="form-control"
                            name="charge"
                            value={formData.charge}
                            onChange={handleChange}
                            placeholder="Enter the charge amount"
                            required
                        />
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="submit" className="btn btn-success px-4">Save Changes</button>
                        <button type="button" className="btn btn-secondary px-4" onClick={() => setIsEditing(false)}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TemplateEditForm;
