import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';


const PaymentDetailsPage = () => {
    const [paymentData, setPaymentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentData = async () => {
            const token = localStorage.getItem('access_token');
            if (!token) {
                setError('No token found. Please log in.');
                setLoading(false);
                return;
            }

            const headers = { Authorization: `Bearer ${token}` };

            try {
                const response = await axios.get(`${config.API_ENDPOINT}/payments/payments/total_received/`, { headers });
                setPaymentData(response.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setError('Unauthorized access. Please log in again.');
                } else {
                    setError(error.message || 'Error fetching payment data.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentData();
    }, []);

    if (loading) {
        return <div className="container mt-4"><div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div></div>;
    }

    if (error) {
        return <div className="container mt-4"><div className="alert alert-danger" role="alert">Error: {error}</div></div>;
    }

    return (
        <div className="container mt-4">
            <h3 className="mb-4">Payment Details</h3>
            {paymentData ? (
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Payment Summary</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <p><strong>Master ID:</strong> {paymentData.master}</p>
                            </div>
                            <div className="col-md-6">
                                <p><strong>Total Received:</strong> ${paymentData.total_received.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        Data last updated at {new Date().toLocaleTimeString()}
                    </div>
                </div>
            ) : (
                <div className="alert alert-info" role="alert">
                    No payment data available.
                </div>
            )}
        </div>
    );
};

export default PaymentDetailsPage;
