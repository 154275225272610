import React, { useState, useEffect } from 'react';
import ProfileCard from './ProfileCard'; 
import VideoCard from './VideoCard'; 
import Navbar from './Navbar'; 
import axios from 'axios';
import FollowersPopup from './FollowersPopup';
import config from '../config';
import LogoutButton from '../login/LogoutButton';  
import { Link } from 'react-router-dom';



import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UpcomingEventsIcon from '@mui/icons-material/EventNote';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import loading_image from '../imagecollection/giphy.webp';
import PersonIcon from '@mui/icons-material/Person'; 
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {Helmet} from "react-helmet";

const getAuthHeaders = () => {
  const token = localStorage.getItem('access_token');
   return token ? { Authorization: `Bearer ${token}` } : {};
};

const likeVideo = (videoId) => {

  
  return axios.post(`${config.API_ENDPOINT}/video/video/${videoId}/like/`, {}, { headers: getAuthHeaders() })
    .then(response => {
  
      return response.data;
    })
    .catch(error => {
      console.error('Error liking video:', error);
      alert(error.response.data.error)
      throw error;
    });
};

const commentOnVideo = (videoId, comment) => {
  return axios.post(`${config.API_ENDPOINT}/video/video/${videoId}/comment/`, { content: comment }, { headers: getAuthHeaders() })
    .then(response => {
    
    })
    .catch(error => {
      console.error('Error posting comment:', error);
    });
};
const editCommentOnVideo = (videoId, comment,setEditCommentId,setEditCommentContent, setFetchVideo, fetchVideo) => {
  return axios.put(`${config.API_ENDPOINT}/video/comment/${videoId}/edit/`, { content: comment }, { headers: getAuthHeaders() })
    .then(response => {
   
      setEditCommentId(null);
      setEditCommentContent("");
      setFetchVideo(!fetchVideo);
      
    })
    .catch(error => {
      console.error('Error Editing comment:', error);
    });
};
const updateViewCount = (videoId) => {
  return axios.post(`${config.API_ENDPOINT}/video/video/${videoId}/view/`, {}, { headers: getAuthHeaders() })
    .then(response => {
 
    })
    .catch(error => {
      console.error('Error updating view count:', error);
    });
};

function Dashbord() {
  const [userFollowers, setUserFollowers] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [displayFollowersPopup, setDisplayFollowersPopup] = useState(false);
  const [selectedList, setSelectedList] = useState("")
  const [userFollowed, setUserFollowed] = useState([]);
  const [parentReplyId, setParentReplyId] = useState(null);
  const [displayProfiles, setDisplayProfiles] = useState(true);
  const [filters, setFilters] = useState({
    location: '',
    rating: '',
    views: '',
    trending: '',
    search: '',
    videoSearch: ''
  });
  const [editCommentId, setEditCommentId] = useState(null); 
  const [editCommentContent, setEditCommentContent] = useState("");
  const [replyComment, setReplyComment] = useState(""); 
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [seachVideo, setSearchVideo] = useState("");


  const [userId, setUserId] = useState(null);
  const [userloading, setuserLoading] = useState(false);


  const [currentPage, setCurrentPage] = useState(1); // Start at the first page
const videosPerPage = 3; // Only 3 videos per page

const indexOfLastVideo = currentPage * videosPerPage;
const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
const [currentPageUrl, setCurrentPageUrl] = useState(`${config.API_ENDPOINT}/api/sorted_videos_view/`);


  const [fetchVideo, setFetchVideo] = useState(false);
  const [videos, setVideos] = useState([]);
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo); // Get the videos for the current page

  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [visibleVideos, setVisibleVideos] = useState(3);
  const [commentInputs, setCommentInputs] = useState({});

  const handlePlayVideo = (videoId) => {
    setPlayingVideoId(videoId);
    updateViewCount(videoId); // Update view count when video is played
  };

  const handlePauseVideo = (videoId) => {
    // Optionally, you can update view count when video is paused if needed
    // updateViewCount(videoId);
  };
  const handleCommentReply = (video) =>{

    const content = {
      "content": replyComment,
      "parent_id": parentReplyId
    }
    return axios.post(`${config.API_ENDPOINT}/video/video/${video}/comment/`,content, { headers: getAuthHeaders() })
    .then(response => {

      setFetchVideo(!fetchVideo)
      setParentReplyId(null)
    })
    .catch(error => {
      console.error('Error Adding Reply comment:', error);
    });

    
    
  }

  const handleFilterChange = (e) => {
    // const { name, value } = e.target;
    // setFilters({
    //   ...filters,
    //   [name]: value
    // });
  };
  const handleLikeVideo = (videoId) => {
    likeVideo(videoId)
      .then(() => {
        // Only update likes if the likeVideo call succeeds
        setVideos(prevVideos =>
          prevVideos.map(video =>
            video.id === videoId ? { ...video, total_likes: video.total_likes + 1 } : video
          )
        );
      })
      .catch((error) => {
        // Optionally handle the error (e.g., show a message to the user)
        console.error('Error handling video like:', error);
      });
  };
  const handleCommentOnVideo = (videoId) => {
    const comment = commentInputs[videoId] || '';
    if (comment.trim()) {
      commentOnVideo(videoId, comment).then(() => {
    setFetchVideo(!fetchVideo)

        setCommentInputs(prevInputs => ({ ...prevInputs, [videoId]: '' })); // Clear the input for this video
      });
    }
  };


  const handleCommentInputChange = (videoId, value) => {
    setCommentInputs(prevInputs => ({ ...prevInputs, [videoId]: value }));
  };

  const handleLoadMoreVideos = () => {
    setVisibleVideos(prev => prev + 3); // Increase the number of visible videos
  };
  const handleDeleteComment = (commentId)=>{

    return axios.delete(`${config.API_ENDPOINT}/video/comment/${commentId}/delete/`, { headers: getAuthHeaders() })
    .then(response => {
   
      setFetchVideo(!fetchVideo)
    })
    .catch(error => {
      console.error('Error Deleting comment:', error);
    });
  }
  const fetchVideos = async (url) => {
    setLoading(true);

    
    try {
      const response = await axios.get(url, { headers: getAuthHeaders() });
      
  
      // Assuming setVideos expects an array of video objects
      setVideos(response.data.results);
  
      setNextPageUrl(response.data.next);
      setPrevPageUrl(response.data.previous);
  
      response?.data.results.forEach((video) => {
        const videoData = {
          id: video.id,
          title: video.title,
          description: video.description,
          file: video.file, // URL for the video file
          location: video.location, // Location of the video
          is_approved: video.is_approved, // Whether the video is approved
          requires_payment: video.requires_payment, // Payment requirement
          tags: video.tags.map(tag => tag.name), // Tags for the video
          uploader: video.uploader, // Uploader's email
          uploader_id: video.uploader_id, // Uploader's ID
          uploader_profile_picture: video.uploader_profile_picture, // Uploader's profile picture
          created_at: video.created_at, // Creation timestamp
          total_views: video.total_views, // Total views count
          total_likes: video.total_likes, // Total likes count
          comments: video.comments // Comments on the video
        };
  
        // Perform any additional operations on `videoData` if needed
      
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const url = seachVideo
      ? `${config.API_ENDPOINT}/videos/search/?search=${seachVideo}`
      : `${config.API_ENDPOINT}/api/sorted_videos_view/`;
    fetchVideos(url);
  }, [seachVideo]);

   // New state for loading

   useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true); // Set loading to true when API call starts
      try {
        const storedUserId = localStorage.getItem('user_id_data'); // Attempt to retrieve user ID from local storage
  
        if (storedUserId) {
          setUserId(storedUserId); // Set user ID from local storage
        } else {
          const response = await axios.get(`${config.API_ENDPOINT}/get-user/`, { headers: getAuthHeaders() });
          setUserId(response.data.user_id); // Set user ID from API response
          localStorage.setItem('user_id_data', response.data.user_id); // Optionally store the user ID in local storage
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Set loading to false when API call is done
      }
    };
  
    fetchUserData();
  }, []);
  
  const handleNextPage = () => {
    if (nextPageUrl) {
      const url = seachVideo 
        ? `${nextPageUrl}&title=${seachVideo}` 
        : nextPageUrl;
      fetchVideos(url);
    }
  };
  const handlePrevPage = () => {
    if (prevPageUrl) {
      const url = seachVideo 
        ? `${prevPageUrl}&title=${seachVideo}` 
        : prevPageUrl;
      fetchVideos(url);
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    // Redirect to the login page
    window.location.href = '/login'; 
  };


  return (
<div style={{ position: 'relative' }}>
  <FollowersPopup 
    selectedList={selectedList} 
    setDisplayFollowersPopup={setDisplayFollowersPopup} 
    displayFollowersPopup={displayFollowersPopup} 
    selectedUserName={selectedUserName} 
    userFollowers={userFollowers}
  />

  {/* <Navbar />  */}

  <div className={`d-flex sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="bg-dark text-white" style={{ width: isCollapsed ? '50px' : '250px', transition: 'width 0.3s' }}>
          <button
            onClick={toggleSidebar}
            className="btn btn-light d-flex align-items-center"
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              borderRadius: '8px',
              padding: '10px 20px',
              backgroundColor: isCollapsed ? '#f8f9fa' : '#e2e6ea',
              color: isCollapsed ? '#343a40' : '#007bff',
            }}
          >
            <i className={`fs-5 bi ${isCollapsed ? 'bi-arrow-right' : 'bi-arrow-left'} me-2`}></i>
            <span className={`${isCollapsed ? 'd-none' : ''}`}>{isCollapsed ? 'Expand' : 'Collapse'}</span>
          </button>
          
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <Tooltip title="Home">
                <Link to="/" className="nav-link" onClick={toggleSidebar}>
                  <HomeIcon style={{ color: 'white' }} />
                  <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Home</span>
                </Link>
              </Tooltip>
            </li>
            <li className="nav-item">
              <Tooltip title="Dashboard">
                <Link to={`/`} className="nav-link" onClick={toggleSidebar}>
                  <DashboardIcon style={{ color: 'white' }} />
                  <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Dashboard</span>
                </Link>
              </Tooltip>
              {userId ? (
                <Tooltip title="Profile">
                <Link to={`/profile/${userId}`} className="nav-link" onClick={toggleSidebar}>
                  <PersonIcon style={{ color: 'white' }} />
                  <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Profile</span>
                </Link>
              </Tooltip>
              ) : (
                <p className="text-muted">Loading...</p>
              )}
            </li>
            <li className="nav-item">
            <Tooltip title="Upcoming Event">
              <Link to="/EventList" className="nav-link" onClick={toggleSidebar}>
                <UpcomingEventsIcon style={{ color: 'white' }} />
                <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Event List</span>
              </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip title="Create Event">
              <Link to="/CreateEvent" className="nav-link" onClick={toggleSidebar}>
                <AddCircleOutlineIcon style={{ color: 'white' }} />
                <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Create Event</span>
              </Link>
            </Tooltip>
          </li>
            <li className="nav-item">
              <Tooltip title="Logout">
                <a href="#" className="nav-link" onClick={() => { handleLogout(); toggleSidebar(); }}>
                  <LogoutIcon style={{ color: 'white' }} />
                  <span className={`ms-1 ${isCollapsed ? 'd-none' : ''}`}>Logout</span>
                </a>
              </Tooltip>
            </li>
          </ul>
        </div>
  
 
      
      <div className="col py-3">
      <div className="container-fluid" >


      <div className="container-fluid mb-4">
      <div className="row">
        <div className="col-12">
          <i className="fs-4 bi-box-arrow-right"></i>
        </div>
      </div>
    </div>

 
 <div className="container-fluid mb-4">
   <div className="row">
     <div className="col-12 mb-4">
       <div className="input-group mb-3">
         <input
           type="text"
           className="form-control"
           value={searchValue}
           onChange={(e)=>setSearchValue(e.target.value)}
           placeholder="Search..."
           aria-label="Search"
           aria-describedby="basic-addon2"
         />
         <span className="input-group-text" id="basic-addon2">
         <button className='btn btn-primary' onClick={()=>setSearchTerm(searchValue)}>Search Profile</button>
         <button className='btn btn-primary' onClick={()=>setSearchVideo(searchValue)} style={{marginLeft:"10px"}}>Search Video</button>
          
         


         </span>
       </div>
       
     </div>

   </div>


 </div>
 
 {/* Buttons */}
 <div className="container-fluid mb-4">
   <div className="row">
     <div className="col-6">
       <button
         onClick={() => setDisplayProfiles(true)}
         className={`btn ${displayProfiles ? 'btn-primary active' : 'btn-outline-primary'} w-100`}
         style={{
           fontWeight: '550',
           fontSize: '20px',
           borderRadius: '6px',
           height: '40px',
           boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
           transition: 'all 0.3s ease-in-out',
           background: displayProfiles ? 'linear-gradient(135deg, #007bff, #0056b3)' : 'transparent',
           color: displayProfiles ? '#fff' : '#007bff',
           borderColor: displayProfiles ? '#007bff' : '#007bff',
           position: 'relative',
         }}
         onMouseEnter={(e) => {
           e.currentTarget.style.background = displayProfiles ? 'linear-gradient(135deg, #0056b3, #003d7a)' : '#e9ecef';
           e.currentTarget.style.transform = 'scale(1.05)';
         }}
         onMouseLeave={(e) => {
           e.currentTarget.style.background = displayProfiles ? 'linear-gradient(135deg, #007bff, #0056b3)' : 'transparent';
           e.currentTarget.style.transform = 'scale(1)';
         }}
         onFocus={(e) => e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'}
         onBlur={(e) => e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.15)'}
       >
         <i className={`bi ${displayProfiles ? 'bi-person-fill' : 'bi-film'} me-2`}></i> {/* Bootstrap Icons */}
         Profiles
       </button>
     </div>
     <div className="col-6">
       <button
         onClick={() => setDisplayProfiles(false)}
         className={`btn ${!displayProfiles ? 'btn-primary active' : 'btn-outline-primary'} w-100`}
         style={{
           fontWeight: '550',
           fontSize: '20px',
           borderRadius: '6px',
           height: '40px',
           boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
           transition: 'all 0.3s ease-in-out',
           background: !displayProfiles ? 'linear-gradient(135deg, #007bff, #0056b3)' : 'transparent',
           color: !displayProfiles ? '#fff' : '#007bff',
           borderColor: !displayProfiles ? '#007bff' : '#007bff',
           position: 'relative',
         }}
         onMouseEnter={(e) => {
           e.currentTarget.style.background = !displayProfiles ? 'linear-gradient(135deg, #0056b3, #003d7a)' : '#e9ecef';
           e.currentTarget.style.transform = 'scale(1.05)';
         }}
         onMouseLeave={(e) => {
           e.currentTarget.style.background = !displayProfiles ? 'linear-gradient(135deg, #007bff, #0056b3)' : 'transparent';
           e.currentTarget.style.transform = 'scale(1)';
         }}
         onFocus={(e) => e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'}
         onBlur={(e) => e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.15)'}
       >
         <i className={`bi ${!displayProfiles ? 'bi-person-fill' : 'bi-film'} me-2`}></i> {/* Bootstrap Icons */}
         Videos
       </button>
     </div>
   </div>
 </div>

 {/* Render ProfileCard components here */}
 { displayProfiles &&
   <div className="row mb-4">
     <ProfileCard 
       setSelectedList={setSelectedList} 
       setSelectedUserName={setSelectedUserName} 
       setUserFollowers={setUserFollowers} 
       setDisplayFollowersPopup={setDisplayFollowersPopup}  
       userFollowed={userFollowed} 
       getAuthHeaders={getAuthHeaders} 
       setUserFollowed={setUserFollowed}
       searchTerm={searchTerm}
     />
   </div>
 }

 {!displayProfiles &&
   <div className="mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexWrap: 'wrap' }}>
     {currentVideos.map(video => (
       <div key={video.id} className="col-md-4 mb-4" style={{ padding: '10px' }}>
         <VideoCard
           video={video}
           isPlaying={playingVideoId === video.id}
           onPlay={() => handlePlayVideo(video.id)}
           onPause={() => handlePauseVideo(video.id)}
           onLike={() => handleLikeVideo(video.id)}
           onCommentInputChange={(e) => handleCommentInputChange(video.id, e.target.value)}
           setReplyComment={setReplyComment}
           onCommentSubmit={() => handleCommentOnVideo(video.id)}
           handleCommentReply={() => handleCommentReply(video.id)}
           commentInputValue={commentInputs[video.id] || ''}
           editCommentId={editCommentId}
           editCommentContent={editCommentContent}
           setEditCommentId={setEditCommentId}
           setEditCommentContent={setEditCommentContent}
           editCommentOnVideo={() => editCommentOnVideo(editCommentId, editCommentContent, setEditCommentId, setEditCommentContent, setFetchVideo, fetchVideo)}
           userId={userId}
           replyComment={replyComment}
           handleDeleteComment={handleDeleteComment}
           parentReplyId={parentReplyId}
           setParentReplyId={setParentReplyId}
         />
       </div>
     ))}
   </div>
 }
        {!displayProfiles &&       <nav className="d-flex justify-content-center align-items-center">
        <button
          className="btn btn-outline-primary"
          onClick={handlePrevPage}
          disabled={!prevPageUrl}
        >
          Previous
        </button>
        <button
          className="btn btn-outline-primary ml-2"
          onClick={handleNextPage}
          disabled={!nextPageUrl}
        >
          Next
        </button>
      </nav>
}
    </div>
  </div>


  </div>
</div>

  );
}

export default Dashbord;