import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './VideoApproval.css'; // Import custom CSS for additional styling
import config from '../config';

const VideoApproval = () => {
  const [pendingVideos, setPendingVideos] = useState([]);
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState(null);

  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    fetchPendingVideos();
    fetchApprovedVideos();
  }, []);

  const fetchPendingVideos = async () => {
    try {
      const response = await axios.get(`${config.API_ENDPOINT}/video/videos/approved/requester-approver_all/`, { headers });
      setPendingVideos(response.data.approved_videos || []);
    } catch (error) {
      console.error('Error fetching pending approval videos', error);
    }
  };

  const fetchApprovedVideos = async () => {
    try {
      const response = await axios.get(`${config.API_ENDPOINT}/video/videos/approved/requester-approver/`, { headers });
      setApprovedVideos(response.data.approved_videos || []);
    } catch (error) {
      console.error('Error fetching approved videos', error);
    }
  };

  const handleFetchVideoDetails = async (videoId) => {
    try {
      const response = await axios.get(`${config.API_ENDPOINT}/video/video/${videoId}/`, { headers });
      setSelectedVideoDetails(response.data);
    } catch (error) {
      console.error('Error fetching video details', error);
    }
  };

  const handleApproveVideo = async (videoId) => {
    try {
      await axios.post(`${config.API_ENDPOINT}/video/videos/approve/${videoId}/`, { action: 'approve' }, { headers });
      fetchPendingVideos();
      fetchApprovedVideos();
    } catch (error) {
      console.error('Error approving the video', error);
    }
  };

  const handleRedirectToVideoPage = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {/* Pending Videos Section */}
        <div className="col-md-6 mb-4">
          <h2 className="mb-4 text-primary">Pending Videos for Approval</h2>
          <div className="card">
            <ul className="list-group list-group-flush">
              {pendingVideos.length > 0 ? (
                pendingVideos.map((videoRequest) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center" key={videoRequest.video}>
                    <div>
                      <button
                        className="btn btn-outline-primary me-2"
                        onClick={() => handleFetchVideoDetails(videoRequest.video)}
                      >
                        View Details
                      </button>
                      Video ID: {videoRequest.video} (Requested by: {videoRequest.requested_by_username})
                    </div>
                    <div>
                      <button
                        className="btn btn-success me-2"
                        onClick={() => handleApproveVideo(videoRequest.video)}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => handleRedirectToVideoPage(videoRequest.video)}
                      >
                        Watch
                      </button>
                    </div>
                  </li>
                ))
              ) : (
                <li className="list-group-item text-center">No pending videos for approval.</li>
              )}
            </ul>
          </div>
        </div>

        {/* Video Details Section */}
        <div className="col-md-6 mb-4">
          {selectedVideoDetails && (
            <div className="card p-3">
              <h3 className="card-title">Video Details</h3>
              <div className="card-body">
                <p><strong>Title:</strong> {selectedVideoDetails.title}</p>
                <p><strong>Description:</strong> {selectedVideoDetails.description}</p>
                <p><strong>Location:</strong> {selectedVideoDetails.location}</p>
                <p><strong>Uploader:</strong> {selectedVideoDetails.uploader}</p>
                <p><strong>Tags:</strong> {selectedVideoDetails.tags.map(tag => tag.name).join(', ')}</p>
                <video className="img-fluid mt-2" controls>
                  <source src={selectedVideoDetails.file} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        {/* Approved Videos Section */}
        <div className="col-12">
          <h2 className="mb-4 text-success">Approved Videos</h2>
          <div className="card">
            <ul className="list-group list-group-flush">
              {approvedVideos.length > 0 ? (
                approvedVideos.map((videoRequest) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center" key={videoRequest.video}>
                    Video ID: {videoRequest.video} (Approved by: {videoRequest.approved_by_username})
                    <button
                      className="btn btn-info"
                      onClick={() => handleRedirectToVideoPage(videoRequest.video)}
                    >
                      Watch
                    </button>
                  </li>
                ))
              ) : (
                <li className="list-group-item text-center">No approved videos.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoApproval;
