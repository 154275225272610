import React from 'react';

const LogoutButton = () => {
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    // Redirect to the login page
    window.location.href = '/login'; 
  };

  return (
    <a
      href="#"
      className="nav-link px-0 align-middle"
      onClick={handleLogout}
    >
      <i className="fs-4 bi-box-arrow-right"></i> 
      <span className="ms-1 d-none d-sm-inline">Logout</span>
    </a>
  );
};

export default LogoutButton;
